import React from "react"
import PropTypes from "prop-types";

class TextInputWithButtons extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.insertMarkup = this.insertMarkup.bind(this);
        this.checkPopUp = this.checkPopUp.bind(this);
        this.showPopUp = this.showPopUp.bind(this);
        this.closePopUp = this.closePopUp.bind(this);
        this.insertQuote = this.insertQuote.bind(this);
        this.quoteUserName = this.quoteUserName.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.state = {
            html: this.props.text || '',
            timer: null
        };
        this.textAreaRef = React.createRef();

    }

    componentDidMount() {
        const elems = document.getElementsByClassName("quotable");
        // console.log('HERE1', elems);
        for (let i = 0; i < elems.length; i++) {
            // console.log('HERE2');
            elems[i].addEventListener("mouseup", this.checkPopUp);
        }

        const elems2 = document.getElementsByClassName("quotable_user_name");
        for (let i = 0; i < elems2.length; i++) {
            elems2[i].addEventListener("mouseup", this.quoteUserName);
        }
    }

    componentWillUnmount() {
        const elems = document.getElementsByClassName("quotable");
        // console.log('HERE1', elems);
        for (let i = 0; i < elems.length; i++) {
            // console.log('HERE2');
            elems[i].removeEventListener("mouseup", this.checkPopUp);
        }

        const elems2 = document.getElementsByClassName("quotable_user_name");
        for (let i = 0; i < elems2.length; i++) {
            elems2[i].removedEventListener("mouseup", this.quoteUserName);
        }
    }

    handleKeyDown(event) {
        if (event.keyCode===13 && event.ctrlKey) {
            this.props.onCtrlEnter(event);
        }
    }

    quoteUserName(event) {
        let form = document.getElementById('add-comment-form');
        if (!form) {
            form = document.getElementById('post-comment-form');
        }
        if (!form) {
            return;
        }

        const end = this.textAreaRef.current.selectionEnd;

        this.textAreaRef.current.setRangeText('<b>' + event.target.innerHTML + '</b>', end, end, 'end');

        this.textAreaRef.current.focus();
        this.changeState('html', this.textAreaRef.current.value, null, this.closePopUp);

    }

    checkPopUp(event) {
        // console.log('HERE3');
        let form = document.getElementById('add-comment-form');
        if (!form) {
            form = document.getElementById('post-comment-form');
        }
        if (!form) {
            return;
        }
        // console.log('HERE4');
        const selection = document.getSelection().toString();
        // console.log('HERE5', selection);
        if (selection) {
            const x = event.clientX;
            const y = event.clientY;
            this.showPopUp(x, y, selection);
        }
    }

    showPopUp(x, y, selection) {
        // console.log('HERE6');
        let popup = document.getElementById("quote-pop-up");
        popup.style.width = "110px";
        popup.style.padding = "10px";
        popup.style.fontSize = "11px";
        popup.style.top = String(y + 5) + 'px';
        popup.style.left = String(x + 5) + 'px';
        popup.dataset.selection = selection;
        popup.addEventListener("click", this.insertQuote);

        this.setState({timer: setTimeout(this.closePopUp, 3000)});
    }

    closePopUp() {
        if (this.state.timer) {
            clearTimeout(this.state.timer);
        }
        let popup = document.getElementById("quote-pop-up");
        popup.style.width = "0";
        popup.style.padding = "0";
        popup.style.fontSize = "0";
        this.setState({timer: null});
    }

    insertQuote() {
        let popup = document.getElementById("quote-pop-up");

        const end = this.textAreaRef.current.selectionEnd;

        this.textAreaRef.current.setRangeText('<span class="quote">' + popup.dataset.selection + '</span>', end, end, 'end');

        this.textAreaRef.current.focus();
        this.changeState('html', this.textAreaRef.current.value, null, this.closePopUp);
    }

    handleChange(e) {
        const target = e.target;
        const name = target.name;
        const value = target.value;
        this.changeState(name, value, e);
    }

    changeState(name, value, event, callback) {
        this.setState({
            [name]: value
        });
        let e = null;
        if (event) {
            e = event;
        } else {
            e = new CustomEvent("input", {
                bubbles: true,
                'detail': {name: name, value: value}
            });
        }
        this.props.onTextChange(e);
        callback;
    }

    insertMarkup(e) {
        const target = e.target;
        const name = target.name;
        let start_tag = '';
        let end_tag = '';

        switch(name) {
            case 'b':
                start_tag = "<b>";
                end_tag = "</b>";
                break;
            case 's':
                start_tag = "<s>";
                end_tag = "</s>";
                break;
            case 'u':
                start_tag = "<u>";
                end_tag = "</u>";
                break;
            case 'i':
                start_tag = "<i>";
                end_tag = "</i>";
                break;
            case 'right':
                start_tag = "<div align=\"right\">";
                end_tag = "</div>";
                break;
            case 'left':
                start_tag = "<div align=\"left\">";
                end_tag = "</div>";
                break;
            case 'center':
                start_tag = "<div align=\"center\">";
                end_tag = "</div>";
                break;
            case 'justify':
                start_tag = "<div align=\"justify\">";
                end_tag = "</div>";
                break;
            case 'a':
                start_tag = '<a href="">';
                end_tag = "</a>";
                break;
            case 'quote':
                start_tag = "<span class=\"quote\">";
                end_tag = "</span>";
                break;
            case 'img':
                start_tag = "<img src=\"\">";
                break;
            case 'more':
                start_tag = "[MORE=читать дальше]";
                end_tag = "[/MORE]";
                break;
        }

        const start = this.textAreaRef.current.selectionStart;
        const end = this.textAreaRef.current.selectionEnd;

        this.textAreaRef.current.setRangeText(end_tag, end, end, 'end');
        this.textAreaRef.current.setRangeText(start_tag, start, start, 'end');

        this.textAreaRef.current.focus();
        this.changeState('html', this.textAreaRef.current.value, null);
    }

    render() {
        return (
            <React.Fragment>
            <div className={"edit-icons " + this.props.edit_icons_class}>
                <img src="/images/icons/editor/b.png" name="b" title="жирный текст" onClick={this.insertMarkup}/>
                <img src="/images/icons/editor/s.png" name="s" title="зачеркнутый текст" onClick={this.insertMarkup}/>
                <img src="/images/icons/editor/u.png" name="u" title="подчеркнутый текст" onClick={this.insertMarkup}/>
                <img src="/images/icons/editor/i.png" name="i" title="курсив" onClick={this.insertMarkup}/>
                <img src="/images/icons/editor/left.png" name="left" title="выровнять по левому краю" onClick={this.insertMarkup}/>
                <img src="/images/icons/editor/right.png" name="right" title="выровнять по правому краю" onClick={this.insertMarkup}/>
                <img src="/images/icons/editor/center.png" name="center" title="выровнять по центру" onClick={this.insertMarkup}/>
                <img src="/images/icons/editor/justify.png" name="justify" title="выровнять по ширине" onClick={this.insertMarkup}/>
                <img src="/images/icons/editor/link.png" name="a" title="вставить ссылку" onClick={this.insertMarkup}/>
                <img src="/images/icons/editor/quote.png" name="quote" title="вставить цитату" onClick={this.insertMarkup}/>
                <img src="/images/icons/editor/image.png" name="img" title="вставить изображение" onClick={this.insertMarkup}/>
                <img src="/images/icons/editor/more.png" name="more" title="скрытый текст" onClick={this.insertMarkup}/>
            </div>
            <textarea className={this.props.inner_class || ''} id={this.props.inner_id || "add-comment-form"} value={this.state.html} name="html" ref={this.textAreaRef} onChange={this.handleChange} placeholder={this.props.placeholder || ''} onKeyDown={this.handleKeyDown}/>
            </React.Fragment>);
    }
}

TextInputWithButtons.propTypes = {
    text: PropTypes.string,
    placeholder: PropTypes.string,
    onTextChange: PropTypes.func,
    onCtrlEnter: PropTypes.func,
    inner_class: PropTypes.string,
    edit_icons_class: PropTypes.string,
    inner_id: PropTypes.string
};

export default TextInputWithButtons