import React from "react"
import PropTypes from "prop-types";

const Functions = require('./functions.js');
const axios = require('axios').default;
import {pstfgrpnt} from "../plain_js/detector.js";
import {beautiful_alert} from "../plain_js/layout.js";

class ShowQuestion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            already_voted: this.props.already_voted,
            show_results: this.props.already_voted || this.props.closed || !this.props.current_user_can_vote,
            answer_ids: [],
            answer_options: this.props.answer_options,
            total_count: this.props.total_count
        };

        this.makeRequest = this.makeRequest.bind(this);
        this.onClickSubmit = this.onClickSubmit.bind(this);
        this.showQuestion = this.showQuestion.bind(this);
        this.showResults = this.showResults.bind(this);
        this.onCheck = this.onCheck.bind(this);

    }

    onCheck(e) {
        console.log(e.target);
        console.log(e.target.checked);
        console.log(e.target.dataset.option_id);
        let new_answer_ids = [];
        let id = Number(e.target.dataset.option_id);
        let checked = e.target.checked;
        let already_added = false;
        this.state.answer_ids.forEach((item) => {
            if (checked) {
                console.log('Here1');
                if (!already_added) {
                    console.log('Here2');
                    if (id < item) {
                        console.log('Here3');
                        new_answer_ids.push(id);
                        already_added = true;
                    }
                }
                new_answer_ids.push(item);
            } else {
                if (id !== item) {
                    new_answer_ids.push(item);
                }
            }
        });
        if (checked && !already_added) {
            new_answer_ids.push(id);
        }
        this.setState({answer_ids: new_answer_ids});
    }

    onClickSubmit(e) {
        e.preventDefault();
        if (this.state.answer_ids.length === 0) {
            beautiful_alert('Нужно выбрать хотя бы один вариант');
            return;
        }
        this.makeRequest();
    }

    showQuestion() {
        this.setState({show_results: false});
    }

    showResults() {
        this.setState({show_results: true});
    }

    makeRequest() {
        let method = 'POST';
        let path = this.props.request_path;

        let data = {
            authenticity_token: Functions.getMetaContent("csrf-token"),
            answer_ids: this.state.answer_ids,
            question_id: this.props.question_id,
            post_id: this.props.post_id
        };

        data['fp'] = pstfgrpnt();

        const self = this;

        axios({method: method, url: path, data: data, responseType: 'json'})
            .then(function (response) {
                let new_answer_options = [];
                let new_total_count = self.state.total_count;
                self.state.answer_options.forEach((item) => {
                    if (self.state.answer_ids.includes(item[2])) {
                        new_answer_options.push([item[0], item[1] + 1, item[2]]);
                        new_total_count += 1;
                    } else {
                        new_answer_options.push(item);
                    }
                });
                self.setState({
                    show_results: true,
                    already_voted: true,
                    answer_options: new_answer_options,
                    total_count: new_total_count
                });
            })
            .catch(function (error) {
                console.log(error);
                console.log(error.response);
                let error_text = '';
                if (error.response && error.response.data && (error.response.data.error || error.response.data.errors)) {
                    if (error.response.data.error) {
                        error_text = error.response.data.error;
                    } else if (error.response.data.errors) {
                        error_text = error.response.data.errors;
                    } else {
                        error_text = 'Неизвестная ошибка. Обратитесь к администратору.';
                    }
                } else {
                    error_text = 'Неизвестная ошибка. Обратитесь к администратору.';
                }
                beautiful_alert(error_text);
            });
    }

    render () {
        return (
            <React.Fragment>
﻿               <div id="voting">

                    <div className="voting-text">
                        {this.props.text}
                    </div>

                    <div className="voting-div">
                        {this.state.answer_options.map((item, index) =>
                            <div className="p-info voting-option" key={'key-' + String(index)}>
                                {(!this.props.closed && !this.state.already_voted && !this.state.show_results) &&
                                    <input className="voting-input" id={'option-' + String(index)} data-option_id={item[2]} type="checkbox" checked={this.state.answer_ids.includes(item[2])} onChange={this.onCheck}/> }
                                <label className="voting-ilabel" htmlFor={'option-' + String(index)}>{item[0]}</label>{(this.props.closed || this.state.already_voted || this.state.show_results) && <span className='voting-count'>{' — ' + String(item[1])}</span>}
                                {(this.props.closed || this.state.already_voted || this.state.show_results) &&
                                    <div className="percent" style={{width: String(Math.round(item[1] * 100 / (this.state.total_count || 1))) + '%'}}></div> }
                            </div>
                        )}
                    </div>
                    <div className="bttn-right">
                        {(!this.props.closed && !this.state.already_voted && !this.state.show_results) && <span id="voting-go" className="bttn-dark bttn" onClick={this.onClickSubmit}>голосовать</span>}
                        {(!this.props.closed && !this.state.already_voted && !this.state.show_results) && <span id="voting-show" className="bttn-dark bttn" onClick={this.showResults}>результат</span>}
                        {(!this.props.closed && !this.state.already_voted && this.state.show_results) && <span id="question-show" className="bttn-dark bttn" onClick={this.showQuestion}>голосование</span>}
                    </div>

                </div>
            </React.Fragment>
        )
    }


}

ShowQuestion.propTypes = {
    text: PropTypes.string,
    closed: PropTypes.bool,
    request_path: PropTypes.string,
    answer_options: PropTypes.array,
    current_user_can_vote: PropTypes.bool,
    already_voted: PropTypes.bool,
    total_count: PropTypes.number,
    question_id: PropTypes.number,
    post_id: PropTypes.number
};

export default ShowQuestion;