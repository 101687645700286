import React from "react"
import PropTypes from "prop-types";

const Functions = require('./functions.js');
const axios = require('axios').default;

class FandomForm extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.closeError = this.closeError.bind(this);
        this.showError = this.showError.bind(this);
        this.state = {
            title: this.props.title
        };
        this.errorsRef = React.createRef();
        this.errorsTextRef = React.createRef();
    }

    handleChange(e) {
        const target = e.target;
        let name, value;
        if (target) {
            name = target.name;
            value = target.value;
        } else {
            value = e.detail.value;
            name = e.detail.name;
        }
        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        let path = '/fandoms/' + String(this.props.id);
        let method = 'POST';
        let data = {
            authenticity_token: Functions.getMetaContent("csrf-token"),
            title: this.state.title,
            id: this.props.id
        };

        const self = this;

        axios({method: method, url: path, data: data, responseType: 'json'})
            .then(function (response) {
                window.location.href = '/fandoms/' + String(response.data.fandom_id);
            })
            .catch(function (error) {
                let error_text = '';
                if (error.response && error.response.data && (error.response.data.error || error.response.data.errors)) {
                    if (error.response.data.error) {
                        error_text = error.response.data.error;
                    } else if (error.response.data.errors) {
                        error_text = error.response.data.errors;
                    } else {
                        error_text = 'Неизвестная ошибка. Обратитесь к администратору.';
                    }
                } else {
                    error_text = 'Неизвестная ошибка. Обратитесь к администратору.';
                }
                self.showError(error_text);
            });
    }

    showError(text) {
        this.errorsRef.current.style.display = 'block';
        this.errorsTextRef.current.innerHTML = text;
    }

    closeError() {
        this.errorsRef.current.style.display = 'none';
    }

    render() {
        return (
            <React.Fragment>
                <form id="add-character">
                    <input type="text" className="add-fandom-input search-inputs" name="title" value={this.state.title} onChange={this.handleChange} />
                    <div className="news-error-sign" ref={this.errorsRef}>
                        <span id="close-sign3" onClick={this.closeError}>&times;</span>
                        <div id="news-form-error" ref={this.errorsTextRef}></div>
                    </div>
                    <input className="bttn-dark save-fandom" type="submit" value="сохранить" onClick={this.handleSubmit}/>
                </form>
            </React.Fragment>
        );
    }
}

FandomForm.propTypes = {
    title: PropTypes.string,
    id: PropTypes.number
};

export default FandomForm;