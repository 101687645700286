import React from "react"
import PropTypes from "prop-types";

const Functions = require('./functions.js');
const axios = require('axios').default;
import {beautiful_alert} from "../plain_js/layout.js";
import ActionWithConfirmation from "./ActionWithConfirmation";

class MailTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            entries: this.props.entries,
            current_page: 1,
            is_first: true,
            is_last: false,
            total_count: this.props.total_count,
            total_pages: this.props.total_pages
        };
        this.toFirstPage = this.toFirstPage.bind(this);
        this.toPrevPage = this.toPrevPage.bind(this);
        this.toNextPage = this.toNextPage.bind(this);
        this.toLastPage = this.toLastPage.bind(this);
        this.getData = this.getData.bind(this);
        this.gotoPage = this.gotoPage.bind(this);
        this.markLetterAsUnread = this.markLetterAsUnread.bind(this);
    }

    markLetterAsUnread(event) {
        const letter_id = event.target.dataset.letter_id;

        let path = this.props.mark_letter_as_unread_path;
        let method = 'POST';

        let data = {
            authenticity_token: Functions.getMetaContent("csrf-token"),
            letter_id: letter_id,
            id: this.props.user_id
        };

        const self = this;

        axios({method: method, url: path, data: data, responseType: 'json'})
            .then(function (response) {
                self.getData(self.state.current_page);
            })
            .catch(function (error) {
                let error_text = '';
                if (error.response && error.response.data && (error.response.data.error || error.response.data.errors)) {
                    if (error.response.data.error) {
                        error_text = error.response.data.error;
                    } else if (error.response.data.errors) {
                        error_text = error.response.data.errors;
                    } else {
                        error_text = 'Неизвестная ошибка. Обратитесь к администратору.';
                    }
                } else {
                    error_text = 'Неизвестная ошибка. Обратитесь к администратору.';
                }
                beautiful_alert(error_text);
            });
    }

    gotoPage(event) {
        this.getData(Number(event.target.value));
    }

    toFirstPage(e) {
        e.preventDefault();
        if (e.target.className == 'pagination-link-inactive') {
            return;
        }
        this.getData(1);
    }

    toPrevPage(e) {
        e.preventDefault();
        if (e.target.className == 'pagination-link-inactive') {
            return;
        }
        this.getData(this.state.current_page - 1);
    }

    toNextPage(e) {
        e.preventDefault();
        if (e.target.className == 'pagination-link-inactive') {
            return;
        }
        this.getData(this.state.current_page + 1);
    }

    toLastPage(e) {
        e.preventDefault();
        if (e.target.className == 'pagination-link-inactive') {
            return;
        }
        this.getData(this.state.total_pages);
    }

    refreshData() {
        this.getData(1);
    }

    getData(page) {
        let path = this.props.data_path;
        let method = 'POST';

        let data = {
            authenticity_token: Functions.getMetaContent("csrf-token"),
            page: page,
            id: this.props.user_id
        };

        const self = this;

        axios({method: method, url: path, data: data, responseType: 'json'})
            .then(function (response) {
                let total_pages = self.state.total_pages;
                let total_count = self.state.total_count;
                if (response.data.total_pages == 0 || response.data.total_pages) {
                    total_pages = response.data.total_pages;
                }
                if (response.data.total_count == 0 || response.data.total_count) {
                    total_count = response.data.total_count;
                }
                self.setState({
                    current_page: page,
                    is_first: (page === 1),
                    is_last: (page === total_pages),
                    entries: response.data.entries,
                    total_pages: total_pages,
                    total_count: total_count
                });
            })
            .catch(function (error) {
                let error_text = '';
                if (error.response && error.response.data && (error.response.data.error || error.response.data.errors)) {
                    if (error.response.data.error) {
                        error_text = error.response.data.error;
                    } else if (error.response.data.errors) {
                        error_text = error.response.data.errors;
                    } else {
                        error_text = 'Неизвестная ошибка. Обратитесь к администратору.';
                    }
                } else {
                    error_text = 'Неизвестная ошибка. Обратитесь к администратору.';
                }
                beautiful_alert(error_text);
            });
    }

    render() {
        return (
            <React.Fragment>
                <div id={this.props.div_id} className="bookmark-content" style={{display:'none'}} >

                    <div className="overflow-div">
                        {this.state.entries.length === 0 && this.props.no_mail_text}
                        <table id="mail-table" className="profile-table">
                            <tbody>
                            {this.state.entries.map((entry) => <tr className={this.props.inbox && entry.opened ? "read-letter read-mail" : "unread-mail"} key={'mail_' + (this.props.inbox ? 'inbox_' : '') + String(entry.id)}>
                                {<td className="mail-status">
                                    {entry.opened ? <img
                                        src="/images/icons/letter.png"
                                        title="прочитанное" title={this.props.inbox ? "пометить как непрочитанное" : ''} data-letter_id={this.props.inbox ? entry.id : ''} onClick={this.props.inbox ? this.markLetterAsUnread : null}/> : <img
                                        src="/images/icons/letter_new.png" title="новое"/>}</td>}
                                {this.props.inbox && <td className="username"><a href={'/users/' + entry.from_user_id}>{entry.from_user_name}</a></td>}
                                {!this.props.inbox && <td className="username"><a href={'/users/' + entry.to_user_id}>{entry.to_user_name}</a></td>}
                                <td className="mail-title"><a className="mail-link" href={'/messages/' + String(entry.id)}>{entry.title}</a></td>
                                <td className="mail-date">{entry.time}</td>
                                <td className="del-mail"><a href="">
                                    <ActionWithConfirmation image_id=""
                                                            image_src="/images/icons/cross.png"
                                                            image_title='удалить' request_method='DELETE'
                                                            request_path={'/messages/' + String(entry.id)}
                                                            cancel_text='нет' yes_text='да'
                                                            confirmation_text='Вы действительно хотите удалить это письмо?'
                                                            callback={this.refreshData.bind(this)} />
                                    </a>
                                </td>
                            </tr>)}
                            </tbody>
                        </table>
                    </div>
                    { this.state.total_pages !== null && this.state.total_pages > 1 && this.state.total_count > 0 &&
                    <div className="user_works_content user-works-page-counter">
                        <a id="first-p" className={this.state.is_first ? 'pagination-link-inactive' : ''} href={''} onClick={this.toFirstPage}>&#171;</a>
                        <a id="previos-p" className={this.state.is_first ? 'pagination-link-inactive' : ''} href={''} onClick={this.toPrevPage}>&#8249;</a>
                        <span id='current-p'>{this.state.current_page}</span>
                        <a id="next-p" className={this.state.is_last ? 'pagination-link-inactive' : ''} href={''} onClick={this.toNextPage}>&#8250;</a>
                        <a id="last-p" className={this.state.is_last ? 'pagination-link-inactive' : ''} href={''} onClick={this.toLastPage}>&#187;</a>
                        <span>
                        <select className="goto-page" id="goto-page" name='goto_page' onChange={this.gotoPage} value={this.state.current_page}>
                            {Array.from({length: this.state.total_pages}, (_, i) => i + 1).map((item) =>
                                <option value={item} key={'gotopage_' + String(item)}>{item}</option>
                            )}
                        </select>
                        </span>
                    </div>}

                    <div id="mail-table-div">
                        <a href="/messages/new">
                        <input id="add-mail" className="bttn-dark" type="submit" value="написать письмо"/>
                        </a>
                    </div>

                </div>

            </React.Fragment>)
    }
}

MailTable.propTypes = {
    entries: PropTypes.array,
    total_pages: PropTypes.number,
    total_count: PropTypes.number,
    user_id: PropTypes.number,
    no_mail_text: PropTypes.string,
    data_path: PropTypes.string,
    div_id: PropTypes.string,
    inbox: PropTypes.bool

}

export default MailTable