import React from "react"
import PropTypes from "prop-types";
import TextInputWithButtons from "./TextInputWithButtons";

const Functions = require('./functions.js');
const axios = require('axios').default;

class ThreadForm extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.closeError = this.closeError.bind(this);
        this.showError = this.showError.bind(this);
        this.state = {
            title: this.props.title || '',
            html: this.props.text || '',
            users: this.props.users || '',
            pinned: this.props.pinned || '',
            question_text: '',
            question_options: ''
        };
        this.errorsRef = React.createRef();
        this.errorsTextRef = React.createRef();
    }

    handleChange(e) {
        const target = e.target;
        let name, value;
        if (target) {
            name = target.name;
            if (name === 'pinned') {
                value = target.checked;
            } else {
                value = target.value;
            }
        } else {
            value = e.detail.value;
            name = e.detail.name;
        }
        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        const path = this.props.data_path;
        const method = this.props.method;
        let data = {
            authenticity_token: Functions.getMetaContent("csrf-token"),
            title: this.state.title,
            text: this.state.html,
            pinned: this.state.pinned,
            users: this.state.users,
            question_text: this.state.question_text,
            answers_text: this.state.question_options
        };

        if (this.props.is_private) {
            data['users'] = this.state.users
        }

        const self = this;

        axios({method: method, url: path, data: data, responseType: 'json'})
            .then(function (response) {
                window.location.href = response.data.redirect_after_path;
            })
            .catch(function (error) {
                let error_text = '';
                if (error.response && error.response.data && (error.response.data.error || error.response.data.errors)) {
                    if (error.response.data.error) {
                        error_text = error.response.data.error;
                    } else if (error.response.data.errors) {
                        error_text = error.response.data.errors;
                    } else {
                        error_text = 'Неизвестная ошибка. Обратитесь к администратору.';
                    }
                } else {
                    error_text = 'Неизвестная ошибка. Обратитесь к администратору.';
                }
                self.showError(error_text);
            });
    }

    showError(text) {
        this.errorsRef.current.style.display = 'block';
        this.errorsTextRef.current.innerHTML = text;
    }

    closeError() {
        this.errorsRef.current.style.display = 'none';
    }

    render() {
        let title = '';
        if (this.props.id) {
            title = 'Редактирование треда';
        } else {
            title = 'Создание треда';
        }
        return (
            <React.Fragment>
                <div id="lc-send-thread">
                    <h2 id="send_thread_title">{title}</h2>
                    <form id="send-thread-form" action="POST">
                        {this.props.is_private && <textarea className="access-list" placeholder="список доступа" value={this.state.users} name={"users"} onChange={this.handleChange} />}
                        <textarea className="thread-title" placeholder="заголовок" name='title' value={this.state.title} onChange={this.handleChange}/>

                        <TextInputWithButtons placeholder='текст сообщения' inner_class="thread-text" name="html" text={this.state.html} onTextChange={this.handleChange} />

                        {this.props.can_edit_pinned &&
                        <div id="pin_thread">
                            <input id="pinned_checkbox" type="checkbox" name='pinned' defaultChecked={this.props.pinned} onChange={this.handleChange}/>
                            <label htmlFor="pinned_checkbox">Закрепить запись</label>
                        </div>
                        }

                        {this.props.can_add_question &&
                        <div id="add_question">
                        <textarea className="question-text" placeholder="вопрос" name='question_text' value={this.state.question_text} onChange={this.handleChange}/>
                        <textarea className="question-options" placeholder="ответы по одному в строке" name='question_options' value={this.state.question_options} onChange={this.handleChange}/>

                        </div>
                        }

                        <div className="news-error-sign" ref={this.errorsRef}>
                            <span id="close-sign3" onClick={this.closeError}>&times;</span>
                            <div id="news-form-error" ref={this.errorsTextRef}></div>
                        </div>

                        <span id="thread-go" className="bttn-dark bttn" onClick={this.handleSubmit}>запостить</span>
                    </form>
                </div>

            </React.Fragment>
        );
    }


}

ThreadForm.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    id: PropTypes.number,
    is_private: PropTypes.bool,
    pinned: PropTypes.bool,
    can_edit_pinned: PropTypes.bool,
    can_add_question: PropTypes.bool,
    users: PropTypes.string,
    method: PropTypes.string,
    data_path: PropTypes.string
};

export default ThreadForm