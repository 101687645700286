import React from "react"
import PropTypes from "prop-types";
import SelectInput from "./SelectInput";
import ComplexSelectInput from "./ComplexSelectInput";
import TextInputWithButtons from "./TextInputWithButtons";
import {beautiful_alert} from "../plain_js/layout";

const Functions = require('./functions.js');
const axios = require('axios').default;

class WorkForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            title: props.work.title || '',
            summary: props.work.summary || '',
            html: props.work.html || '',
            notes: props.work.notes || '',
            moderator_notes: props.work.moderator_notes || '',
            translation: props.work.translation,
            warnings_text: props.work.warnings_text || '',
            rating_id: props.work.rating_id || '',
            nomination_id: props.work.nomination_id || '',
            paring: props.work.paring || [],
            authors_string: props.work.authors_string,
            fandoms_order: props.work.fandoms_order || [],
            genres_order: props.work.genres_order || [],
            work_type: (props.work.work_type === 0 && props.work.id || props.work.work_type) ? props.work.work_type : '',
            original_author: props.work.original_author || '',
            original_link: props.work.original_link || '',
            original_title: props.work.original_title || '',
            translation_permission: props.work.translation_permission,
            sources_string: props.work.sources_string || '',
            treks_string: props.work.treks_string || '',
            videosources_string: props.work.videosources_string || '',
            embedding_code: props.work.embedding_code || '',
            download_link: props.work.download_link || '',
            betas_string: props.work.betas_string || '',
            selected_categories: props.work.selected_categories,
            fandom_plus: '',
            character_plus: '',
            saved_images: props.work.saved_images,
            uploading_images: [],
            active_characters: this.activeCharacters(props.work.fandoms_order),
            work_id: props.work.id,
            characters: this.props.characters,
            characters_by_fandom: this.props.characters_by_fandom,
            fandoms: this.props.fandoms
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.showError = this.showError.bind(this);
        this.closeError = this.closeError.bind(this);
        this.addFandom = this.addFandom.bind(this);
        this.addCharacter = this.addCharacter.bind(this);
        this.showModal = this.showModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.processFandoms = this.processFandoms.bind(this);
        this.processCharacters = this.processCharacters.bind(this);
        this.processNewData = this.processNewData.bind(this);
        this.refreshFandomsList = this.refreshFandomsList.bind(this);
        this.refreshCharactersList = this.refreshCharactersList.bind(this);
        this.reportSuccess = this.reportSuccess.bind(this);
        this.activeCharacters = this.activeCharacters.bind(this);
        this.handleDeleteSavedImage = this.handleDeleteSavedImage.bind(this);
        this.handleDeleteUploadingImage = this.handleDeleteUploadingImage.bind(this);
        this.handlePreview = this.handlePreview.bind(this);

        this.imageControlTextRef = React.createRef();
        this.translationRef = React.createRef();
        this.textRef = React.createRef();
        this.imageRef = React.createRef();
        this.collageRef = React.createRef();
        this.videoRef = React.createRef();
        this.errorsRef = React.createRef();
        this.errorsTextRef = React.createRef();
        this.betaRef = React.createRef();
        this.notesRef = React.createRef();
        this.previewDivRef = React.createRef();
        this.previewTextRef = React.createRef();
    }

    handleDeleteSavedImage(e) {
        let new_saved_images = [];
        const index = Number(e.target.getAttribute('name'));
        this.state.saved_images.forEach((img) => {
            if (img.index != index) {
                new_saved_images.push(img);
            }
        });
        this.setState({
           saved_images: new_saved_images
        });
    }

    handleDeleteUploadingImage(e) {
        let new_uploading_images = [];
        const delete_index = Number(e.target.getAttribute('name'));
        this.state.uploading_images.forEach((img, index) => {
            if (delete_index != index) {
                new_uploading_images.push(img);
            }
        });
        this.setState({
            uploading_images: new_uploading_images
        });
    }

    addFandom(e) {
        e.preventDefault();
        this.showModal('Добавьте фэндом или несколько через точку с запятой:', this.processFandoms)
    }

    addCharacter(e) {
        e.preventDefault();
        this.showModal('Добавьте персонажа или несколько через точку с запятой:', this.processCharacters, true)
    }

    showModal(text, callback, extra_list) {
        document.getElementById("alert-modal").classList.add("show-modal");
        let new_text = '';
        if (extra_list) {
            let list_items = '<option value=""></option>';
            Object.keys(this.state.fandoms).forEach((key) => {
                list_items += ("<option value=" + String(this.state.fandoms[key][1]) + ">" + this.state.fandoms[key][0] + "</option>")
            });
            new_text = "<span class='instruction'>Выберите фэндом: </span>" + "<select class='modal-select' id='select_fandom_to_add_characters' value=0>" + list_items + "</select><span class='instruction'>" + text + "</span><textarea id='new-work-data'></textarea>";
        } else {
            new_text = text + "<textarea id='new-work-data'></textarea>";
        }
        document.getElementById("warning").innerHTML = new_text;
        let cancel_button = document.getElementById("out");
        cancel_button.style.display = "inline-block";
        cancel_button.addEventListener('click', this.closeModal, false);
        let ok_button = document.getElementById("in");
        ok_button.innerHTML = "ok";
        ok_button.addEventListener('click', callback, false);
    }

    closeModal(e) {
        e.preventDefault();
        document.getElementById("alert-modal").classList.remove("show-modal");
        const cancel_button = document.getElementById("out");
        cancel_button.removeEventListener('click', this.closeModal);
        const ok_button = document.getElementById("in");
        ok_button.removeEventListener('click', this.processFandoms);
        ok_button.removeEventListener('click', this.processCharacters);
    }

    processFandoms(e) {
        e.preventDefault();
        this.processNewData('/add_fandoms', 'Нужно указать хотя бы один фэндом', this.refreshFandomsList);
    }

    processCharacters(e) {
        e.preventDefault();
        this.processNewData('/add_characters', 'Нужно указать хотя бы одного персонажа', this.refreshCharactersList, true);
    }

    processNewData(path, alert_text, callback, with_list) {
        let fandom_id = null;
        if (with_list) {
            const fandom = document.getElementById("select_fandom_to_add_characters");
            if (fandom && fandom.value) {
                fandom_id = fandom.value;
            } else {
                beautiful_alert('Надо выбрать конкретный фэндом');
                return;
            }
        }
        const input_data = document.getElementById("new-work-data");
        if (input_data && input_data.value) {
            document.getElementById("alert-modal").classList.remove("show-modal");
            const cancel_button = document.getElementById("out");
            cancel_button.removeEventListener('click', this.closeModal);
            const ok_button = document.getElementById("in");
            ok_button.removeEventListener('click', this.processFandoms);
            ok_button.removeEventListener('click', this.processCharacters);

            const self = this;

            let data = {
                authenticity_token: Functions.getMetaContent("csrf-token")
            };
            data['input_data'] = input_data.value;
            if (with_list) {
                data['fandom_id'] = fandom_id;
            }

            axios({method: 'POST', url: path, data: data, responseType: 'json'})
                .then(function (response) {
                    callback();
                })
                .catch(function (error) {
                    let error_text = '';
                    if (error.response && error.response.data && (error.response.data.error || error.response.data.errors)) {
                        if (error.response.data.error) {
                            error_text = error.response.data.error;
                        } else if (error.response.data.errors) {
                            error_text = error.response.data.errors;
                        } else {
                            error_text = 'Неизвестная ошибка. Обратитесь к администратору.';
                        }
                    } else {
                        error_text = 'Неизвестная ошибка. Обратитесь к администратору.';
                    }
                    self.showError(error_text);
                });
        } else {
            beautiful_alert(alert_text);
        }
    }

    refreshFandomsList() {
        let self = this;
        axios({method: 'GET', url: '/get_fandoms_user_list', responseType: 'json'})
            .then(function (response) {
                self.setState({fandoms: response.data.fandoms}, self.reportSuccess);
            })
            .catch(function (error) {
                let error_text = '';
                if (error.response && error.response.data && (error.response.data.error || error.response.data.errors)) {
                    if (error.response.data.error) {
                        error_text = error.response.data.error;
                    } else if (error.response.data.errors) {
                        error_text = error.response.data.errors;
                    } else {
                        error_text = 'Неизвестная ошибка. Обратитесь к администратору.';
                    }
                } else {
                    error_text = 'Неизвестная ошибка. Обратитесь к администратору.';
                }
                self.showError(error_text);
            });
    }

    refreshCharactersList() {
        let self = this;
        axios({method: 'GET', url: '/get_characters_user_list', responseType: 'json'})
            .then(function (response) {
                self.setState({characters: response.data.characters, characters_by_fandom: response.data.characters_by_fandom}, self.reportSuccess);
            })
            .catch(function (error) {
                let error_text = '';
                if (error.response && error.response.data && (error.response.data.error || error.response.data.errors)) {
                    if (error.response.data.error) {
                        error_text = error.response.data.error;
                    } else if (error.response.data.errors) {
                        error_text = error.response.data.errors;
                    } else {
                        error_text = 'Неизвестная ошибка. Обратитесь к администратору.';
                    }
                } else {
                    error_text = 'Неизвестная ошибка. Обратитесь к администратору.';
                }
                self.showError(error_text);
            });
    }

    reportSuccess() {
        this.updateAvailableCharactersList(this.state.fandoms_order);
        beautiful_alert('Список успешно обновлен!');
    }

    closeError() {
        this.errorsRef.current.style.display = 'none';
    }

    showError(text) {
        this.errorsRef.current.style.display = 'block';
        this.errorsTextRef.current.innerHTML = text;
    }

    handleSubmit(event) {
        event.preventDefault();

        let method = '';

        const formData = new FormData();
        formData.append('authenticity_token', Functions.getMetaContent("csrf-token"));

        Object.keys(this.state).forEach((key) => {
            if (!(key === 'characters' || key === 'active_characters' || key === 'fandoms' || key === 'characters_by_fandom')) {
                if (this.state[key] || this.state[key] === 0) {
                    if (key === 'uploading_images') {
                        this.state[key].forEach((item) => {
                            formData.append(key + '[]', item, item.name);
                        });
                    } else if (key === 'saved_images') {
                        formData.append(key, this.state[key].map((item) => item.id));
                    } else {
                        formData.append(key, this.state[key]);
                    }
                }
            }
        });

        let path = '';
        if (this.state.work_id) {
            path = '/works/' + String(this.state.work_id);
            method = 'PATCH';
        } else {
            path = '/works';
            method = 'POST';
        }

        const self = this;

        axios({method: method, url: path, data: formData, responseType: 'json', headers: {
                'content-type': 'multipart/form-data'
            }})
            .then(function (response) {
                document.location.href = '/works/' + String(response.data.id)
            })
            .catch(function (error) {
                console.log(error);
                let error_text = 'Неизвестная ошибка. Свяжитесь с администратором.';
                if (error.response && error.response.data && error.response.data.errors) {
                    error_text = error.response.data.errors;
                } else if (error.response.statusText) {
                    error_text = error.response.statusText;
                }
                self.showError(error_text);
            });

    }

    handlePreview(event) {
        event.preventDefault();

        let method = 'POST';

        const formData = new FormData();
        formData.append('authenticity_token', Functions.getMetaContent("csrf-token"));

        Object.keys(this.state).forEach((key) => {
            if (!(key === 'characters' || key === 'active_characters' || key === 'fandoms' || key === 'characters_by_fandom')) {
                if (this.state[key] || this.state[key] === 0) {
                    if (key === 'uploading_images') {
                        this.state[key].forEach((item) => {
                            formData.append(key + '[]', item, item.name);
                        });
                    } else if (key === 'saved_images') {
                        formData.append(key, this.state[key].map((item) => item.id));
                    } else {
                        formData.append(key, this.state[key]);
                    }
                }
            }
        });

        let path = '/works/preview';

        const self = this;

        axios({method: method, url: path, data: formData, responseType: 'json', headers: {
                'content-type': 'multipart/form-data'
            }})
            .then(function (response) {
                self.previewDivRef.current.innerHTML = response.data.data;
                self.previewDivRef.current.style.display = 'block';
                const more_labels = self.previewDivRef.current.getElementsByClassName("more_label");
                const openMore = function() {
                    this.style.display = 'none';
                    this.nextSibling.style.display = 'block';
                };
                for (let i = 0; i < more_labels.length; i++) {
                    more_labels[i].addEventListener('click', openMore, false);
                }

                const mores = self.previewDivRef.current.getElementsByClassName("more_container");
                const closeMore = function() {
                    this.style.display = 'none';
                    this.previousSibling.style.display = 'block';
                };
                for (let i = 0; i < mores.length; i++) {
                    mores[i].addEventListener('dblclick', closeMore, false);
                }
            })
            .catch(function (error) {
                console.log(error);
                let error_text = 'Неизвестная ошибка. Свяжитесь с администратором.';
                if (error.response && error.response.data && error.response.data.errors) {
                    error_text = error.response.data.errors;
                } else if (error.response.statusText) {
                    error_text = error.response.statusText;
                }
                self.showError(error_text);
            });

    }

    handleInputChange(event) {
        let value, name;

        const target = event.target;
        if (target) {
            name = target.name;
            if (name === 'image') {
                value = target.files[0];
                let new_uploading_images = this.state.uploading_images;
                new_uploading_images.push(value);
                this.setState({
                    'uploading_images': new_uploading_images
                });
                return;
            } else {
                value = target.value;
            }
        } else {
            value = event.detail.value;
            name = event.detail.name;
        }

        this.setState({
            [name]: value
        }, () => {
            if (name === 'work_type') {
                this.handleWorkTypeChange(Number(value));
            }

            if (name === 'fandoms_order') {
                this.updateAvailableCharactersList(value);
            }
        });
    }

    handleWorkTypeChange(value) {
        this.previewDivRef.current.style.display = 'none';
        switch(value) {
            case 0:
                this.textRef.current.style.display = 'block';
                this.translationRef.current.style.display = 'none';
                this.imageRef.current.style.display = 'none';
                this.collageRef.current.style.display = 'none';
                this.videoRef.current.style.display = 'none';
                this.betaRef.current.style.display = 'block';
                this.notesRef.current.style.display = 'block';

                break;
            case 1:
                this.textRef.current.style.display = 'block';
                this.translationRef.current.style.display = 'block';
                this.imageRef.current.style.display = 'none';
                this.collageRef.current.style.display = 'none';
                this.videoRef.current.style.display = 'none';
                this.betaRef.current.style.display = 'block';
                this.notesRef.current.style.display = 'block';

                break;
            case 2:
                this.textRef.current.style.display = 'none';
                this.translationRef.current.style.display = 'none';
                this.imageRef.current.style.display = 'block';
                this.collageRef.current.style.display = 'none';
                this.videoRef.current.style.display = 'none';
                this.betaRef.current.style.display = 'none';
                this.notesRef.current.style.display = 'block';

                break;
            case 3:
                this.textRef.current.style.display = 'none';
                this.translationRef.current.style.display = 'none';
                this.imageRef.current.style.display = 'none';
                this.collageRef.current.style.display = 'none';
                this.videoRef.current.style.display = 'block';
                this.betaRef.current.style.display = 'none';
                this.notesRef.current.style.display = 'block';

                break;
            case 4:
                this.textRef.current.style.display = 'none';
                this.translationRef.current.style.display = 'none';
                this.imageRef.current.style.display = 'block';
                this.collageRef.current.style.display = 'block';
                this.videoRef.current.style.display = 'none';
                this.betaRef.current.style.display = 'none';
                this.notesRef.current.style.display = 'block';

                break;
            default:
                // console.log('hmmmm');
        }
    }

    updateAvailableCharactersList(fandoms_order) {
        this.setState({
            'active_characters': this.activeCharacters(fandoms_order)
        });
    }

    activeCharacters(fandoms_order) {
        if (fandoms_order && fandoms_order.length > 0) {
            let active_characters = [];
            fandoms_order.forEach((id) => {
                let fandom_characters = (this.state || this.props).characters_by_fandom[id];
                if (fandom_characters) {
                    active_characters = active_characters.concat((this.state || this.props).characters_by_fandom[id]);
                }

            });
            return active_characters;
        } else {
            if (this.state) {
                return this.state.characters;
            } else {
                return this.props.characters;
            }
        }
    }

    componentDidMount() {
        this.handleWorkTypeChange(Number(this.state.work_type));
    }

    render() {
        return(
            <React.Fragment>
                <div id="autor-form-div">
                    <h2>заявка на участие</h2>
                    <form id="autor-form" mane="send-form" method="post" onSubmit={this.handleSubmit}>
                        {this.props.work.moderator_notes_enabled &&
                            <div id="moderator-note">
                                <span className="instruction">Примечания для модератора.
                                    <br/>Чтобы текст быстро и успешно прошел модерацию, в этом поле вы можете оставить информацию по спорным моментам: возраст и гендер персонажей; важность и объем сюжетных линий про отношения между героями одного пола/гендера; дата первой публикации работы и ссылка на нее; была ли работа отредактирована по сравнению с первоначальной версией, и какие внесены правки (важно для номинации олдскула) и др.
                                    <br/>Этот комментарий виден только вам, модераторам и админам (необязательный пункт)</span>
                                <input type="text" name="moderator_notes" value={this.state.moderator_notes || ''} onChange={this.handleInputChange} />
                            </div>
                        }
                        <div id="section">
                            <span className="instruction">Укажите, пожалуйста, какую работу вы хотите отправить на конкурс</span>
                            <SelectInput selected={ (this.state.work_type || this.state.work_type === 0) ? Number(this.state.work_type) : -1 } include_blank={true} blank_name={''} list={{}} list_array={this.props.work_types} name={'work_type'} onSelectChange={this.handleInputChange} />
                        </div>

                        <div id="nomination">
                            <span className="instruction">Выберите номинацию</span>
                            <SelectInput selected={ this.state.nomination_id ? Number(this.state.nomination_id) : -1 } include_blank={true} blank_name={''} list_array={this.props.nominations} name={'nomination_id'} onSelectChange={this.handleInputChange} />
                        </div>

                        <div id="co-autor">
                            <span className="instruction">Вы можете указать соавтора (только если он зарегистрирован на сайте). Если их несколько (и они зарегистрированы), разделите их точкой с запятой (необязательный пункт)</span>
                            <input type="text" name="authors_string" value={this.state.authors_string} onChange={this.handleInputChange} />
                        </div>

                        <div id="beta" ref={this.betaRef}>
                            <span className="instruction">Вы можете указать бету – одну или нескольких, разделив их точкой с запятой (необязательный пункт)</span>
                            <input type="text" name="betas_string" value={this.state.betas_string} onChange={this.handleInputChange} />
                        </div>

                        <div id="fandom">
                            <span className="instruction">Выберите один или несколько фэндомов. Если в списке нет нужного вам фэндома, нажмите кнопку "добавить фэндом"</span>
                            <ComplexSelectInput customStyles={this.props.customStyles} customFocusedStyles={this.props.customFocusedStyles} with_suggestions={true} key={"fandoms_key_" + String(this.state.fandoms.count)} selected_array={ this.state.fandoms_order || [] } list_array={this.state.fandoms} dictionary_array={this.state.fandoms} name={'fandoms_order'} type={'fandom'} onSelectChange={this.handleInputChange} can_delete_from_middle={true} />
                        </div>

                        <input id="add-fandom-work-form" className="bttn-light bttn-work-form" type="submit" value="добавить фэндом" onClick={this.addFandom}/>

                        <div id="character">
                            <span className="instruction">Укажите персонажей. Если они составляют пейринг, разделите их косой чертой. Если в списке нет нужного вам персонажа, нажмите кнопку "добавить персонажа"</span>
                            <ComplexSelectInput customStyles={this.props.customStyles} customFocusedStyles={this.props.customFocusedStyles}  with_suggestions={true} key={"characters_key_" + String(this.state.fandoms.count) + '_' + String(this.state.characters.count)} selected_array={ this.state.paring || [] } list_array={this.state.active_characters} dictionary_array={this.state.characters} name={'paring'} type={'character'} onSelectChange={this.handleInputChange} can_delete_from_middle={true} />
                        </div>

                        <input id="add-character-work-form" className="bttn-light bttn-work-form" type="submit" value="добавить персонажа" onClick={this.addCharacter}/>

                        <div id="rating">
                            <span className="instruction">Выберите рейтинг</span>
                            <SelectInput selected={ this.state.rating_id ? Number(this.state.rating_id) : -1 } include_blank={true} blank_name={''} list={this.props.ratings} name={'rating_id'} onSelectChange={this.handleInputChange} />
                        </div>

                        <div id="category">
                            <span className="instruction">Выберите одну или несколько категорий</span>
                            <ComplexSelectInput selected_array={ this.state.selected_categories || [] } list={this.props.categories} dictionary={this.props.categories} name={'selected_categories'} type={'category'} onSelectChange={this.handleInputChange} />
                        </div>

                        <div id="genre">
                            <span className="instruction">Выберите один или несколько жанров</span>
                            <ComplexSelectInput selected_array={ this.state.genres_order || [] } list_array={this.props.genres} dictionary_array={this.props.genres} name={'genres_order'} type={'genre'} onSelectChange={this.handleInputChange} />
                        </div>

                        <div id="worning">
                            <span className="instruction">Добавьте предупреждения (необязательный пункт)</span>
                            <input type="text" name="warnings_text" value={this.state.warnings_text || ''} onChange={this.handleInputChange} />
                        </div>

                        <div id="note" ref={this.notesRef}>
                            <span className="instruction">Также вы можете добавить примечания (необязательный пункт)</span>
                            <input type="text" name="notes" value={this.state.notes || ''} onChange={this.handleInputChange} />
                        </div>

                        <div ref={this.translationRef}>
                            <div id="autor-name">
                                <span className="instruction">Укажите автора оригинала</span>
                                <input type="text" name="original_author" value={this.state.original_author || ''} onChange={this.handleInputChange} />
                            </div>

                            <div id="autor-title">
                                <span className="instruction">Укажите название оригинала</span>
                                <input type="text" name="original_title" value={this.state.original_title || ''} onChange={this.handleInputChange} />
                            </div>

                            <div id="autor-link">
                                <span className="instruction">Укажите ссылку на оригинал</span>
                                <input type="text" name="original_link" value={this.state.original_link || ''} onChange={this.handleInputChange} />
                            </div>

                            <div id="resolution">
                                <span className="instruction">Разрешение на перевод</span>
                                <SelectInput selected={this.state.translation_permission ? Number(this.state.translation_permission) : -1 } include_blank={true} list={this.props.translation_permissions} name={'translation_permission'} onSelectChange={this.handleInputChange} />
                            </div>
                        </div>

                        <div id="own-title">
                            <span className="instruction">Название</span>
                            <input type="text" name="title" value={this.state.title || ''} onChange={this.handleInputChange} />
                        </div>

                        <div id="text-summary">
                            <span className="instruction">Краткое содержание</span>
                            <textarea id="summary" type="text" value={this.state.summary || ''} name="summary" onChange={this.handleInputChange} />
                        </div>

                        <div ref={this.textRef}>
                            <div id="nominee-text">
                                <span className="instruction">Текст (для разметки можете использовать HTML)</span>
                                <TextInputWithButtons name="html" text={this.state.html || ''} onTextChange={this.handleInputChange} />
                            </div>
                        </div>

                        <div id="nominee-art" ref={this.imageRef}>
                            <span className="instruction">Загрузите изображения</span>
                            <div id="loaded-files">
                                { this.state.saved_images.map((img) =>
                                <div className="file-namber" key={String(img.index)}>файл {String(img.index)}
                                    <span className="del-pic" name={String(img.index)} onClick={this.handleDeleteSavedImage}>&#215;</span>
                                </div>)}
                                {this.state.uploading_images.map((img, index) =>
                                    <div className="file-namber" key={img.name + String(index) + String(Math.random())}> {img.name}
                                        <span className="del-pic" name={String(index)} onClick={this.handleDeleteUploadingImage}>&#215;</span>
                                    </div>)}
                            </div>
                            <label id="pic-upload"><span id="pic-status" ref={this.imageControlTextRef}>Добавьте файл</span>
                                <input type="file" name="image" accept="image/*" onChange={this.handleInputChange} /><br/>
                            </label>
                        </div>

                        <div id="source-collage" ref={this.collageRef}>
                            <span className="instruction">Укажите исходники (по одной ссылке в строке)</span>
                            <textarea type="text" name="sources_string" value={this.state.sources_string} onChange={this.handleInputChange} />
                        </div>

                        <div ref={this.videoRef}>
                            <div id="source-audio">
                                <span className="instruction">Укажите названия трека (по одному названию в строке)</span>
                                <textarea type="text" name="treks_string" value={this.state.treks_string} onChange={this.handleInputChange} />
                            </div>

                            <div id="source-video">
                                <span className="instruction">Укажите названия использованных видеоматериалов (по одному названию в строке)</span>
                                <textarea type="text" name="videosources_string" value={this.state.videosources_string} onChange={this.handleInputChange} />
                            </div>

                            <div id="video-cod">
                                <span className="instruction">Скопируйте код для встраивания</span>
                                <textarea type="text" name="embedding_code" value={this.state.embedding_code} onChange={this.handleInputChange} />
                            </div>

                            <div id="video-link">
                                <span className="instruction">Скопируйте ссылку на скачивание</span>
                                <input type="text" name="download_link" value={this.state.download_link} onChange={this.handleInputChange} />
                            </div>
                        </div>

                        <div className="sign" ref={this.errorsRef}>
                            <span id="close-sign3" onClick={this.closeError}>&times;</span>
                            <div id="autor-form-error" ref={this.errorsTextRef}></div>
                        </div>


                        <div id="preview-div" ref={this.previewDivRef}></div>
                        <div id="work-form-buttons">
                            <input id="preview-text" ref={this.previewTextRef} className="bttn-dark" onClick={this.handlePreview} value="предпросмотр" readOnly={true}/>
                            <input id="send-text" className="bttn-dark" type="submit" value="отправить"/>
                        </div>
                    </form>
                </div>
            </React.Fragment>
        );
    }
}

WorkForm.propTypes = {
    work: PropTypes.object,
    work_types: PropTypes.array,
    nominations: PropTypes.array,
    fandoms: PropTypes.array,
    characters: PropTypes.array,
    characters_by_fandom: PropTypes.object,
    ratings: PropTypes.object,
    categories: PropTypes.object,
    genres: PropTypes.array,
    translation_permissions: PropTypes.object,
    customStyles: PropTypes.array,
    customFocusedStyles: PropTypes.object
};

export default WorkForm