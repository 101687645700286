import React from "react"
const Functions = require('./functions.js');
const axios = require('axios').default;

class AgeWarning extends React.Component {
    constructor(props) {
        super(props);
        this.handleClickStay = this.handleClickStay.bind(this);
    }

    handleClickStay(event) {
        event.preventDefault();

        axios({method: 'POST', url: '/set_warned', data: {authenticity_token: Functions.getMetaContent("csrf-token")}, responseType: 'json'})
            .then(function (response) {
                document.getElementById("modal").classList.remove("show-modal");
            })
            .catch(function (error) {
                console.log('age warning unknown error');
                console.log(error.response);
            });
    }

    render () {
        return(
            <React.Fragment>
                <div id="modal" className="show-modal">
                    <div id="angle"></div>
                    <div id="reports-block">
                        <span id="warning">Сайт содержит материалы, посвященные нетрадиционным отношениям. Если вам не исполнилось 18 лет, покиньте, пожалуйста, ресурс.</span>
                        <div className="bttns">
                            <a id="out" className="bttn-light bttn"
                               href="https://www.google.com/">уйти</a>
                            <a id="in" className="bttn-light bttn" href="#" onClick={this.handleClickStay}>остаться</a>
                            <a id="ok" className="bttn-light bttn invis" href="#">ok</a>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default AgeWarning