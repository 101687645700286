import React from "react"
import PropTypes from "prop-types";
import Select from 'react-select'

class SelectInput extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        let event;
        if (e.value) {
            event = new CustomEvent("input", { bubbles: true, 'detail': {name: this.props.name, value: e.value} });
        } else {
            event = e;
        }
        this.props.onSelectChange(event);
    }

    render () {
        let listItems = [];
        let options = [];
        if (this.props.include_blank) {
            listItems.push(<option value={this.props.blank_value || -1} key={-1}>{this.props.blank_name}</option>);
        }
        if (this.props.list_array) {
            this.props.list_array.forEach((elem) => {
                listItems.push(<option value={elem[1]} key={elem[1]}>{elem[0]}</option>);
                options.push({value: elem[1], label: elem[0]});
            });
        } else {
            Object.keys(this.props.list || {}).sort().forEach((list_key) => {
                listItems.push(<option value={list_key} key={list_key}>{this.props.list[list_key]}</option>);
                options.push({value: list_key, label: this.props.list[list_key]});
            });
        }

        let customStyles = {};
        const self = this;
        (this.props.customStyles || []).forEach((item) => {
            customStyles[item[0]] = function(provided, state) {
                return state.isFocused && self.props.customFocusedStyles && self.props.customFocusedStyles[item[0]] ? {...self.props.customFocusedStyles[item[0]]} : {...item[1]};
            };
        });

        return (
            <React.Fragment>
                {this.props.with_suggestions ? <Select styles={customStyles} placeholder={this.props.blank_name || ''} value={this.props.selected} className={'select-with-suggestions'} onChange={this.handleChange} options={options} /> : <select className={this.props.class_name} name={this.props.name} onChange={this.handleChange} value={this.props.selected}>{listItems}</select>}
            </React.Fragment>
        )
    }


}

SelectInput.propTypes = {
    list: PropTypes.object,
    list_array: PropTypes.array,
    onSelectChange: PropTypes.func,
    selected: PropTypes.number,
    name: PropTypes.string,
    blank_name: PropTypes.string,
    blank_value: PropTypes.string,
    class_name: PropTypes.string,
    include_blank: PropTypes.bool,
    with_suggestions: PropTypes.bool,
    customStyles: PropTypes.array,
    customFocusedStyles: PropTypes.object
};

export default SelectInput;