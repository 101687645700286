import React from "react"
import PropTypes from "prop-types";

const Functions = require('./functions.js');
const axios = require('axios').default;
import {beautiful_alert} from "../plain_js/layout.js";

import ActionWithConfirmation from "./ActionWithConfirmation";
import TextInputWithButtons from "./TextInputWithButtons";


class UserProfile extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleToggleCommentsBan = this.handleToggleCommentsBan.bind(this);
        this.handleToggleMakeFavoritesVisible = this.handleToggleMakeFavoritesVisible.bind(this);
        this.handleToggleAllowCommentsForOwnedTexts = this.handleToggleAllowCommentsForOwnedTexts.bind(this);
        this.state = {
            user_avatar_src: this.props.user_avatar_src || '',
            user_email: '',
            user_name: '',
            html: this.props.user_about || '',
            user_links: this.props.user_links || {},
            ficbook: this.props.user_links['мой ficbook'] || '',
            diary: this.props.user_links['мой diary'] || '',
            ao3: this.props.user_links['мой ао3'] || '',
            twitter: this.props.user_links['мой twitter'] || '',
            comments_enabled: this.props.comments_enabled,
            make_favorites_visible: this.props.make_favorites_visible,
            allow_comments_for_owned_texts: this.props.allow_comments_for_owned_texts,
            avatar: null
        };
        this.imageControlTextRef = React.createRef();
    }

    handleToggleAllowCommentsForOwnedTexts(e) {
        let allow_comments_for_owned_texts = e.target.checked;
        const self = this;
        let data = {
            authenticity_token: Functions.getMetaContent("csrf-token"),
            user_id: this.props.user_id,
            allow_comments_for_owned_texts: allow_comments_for_owned_texts
        };
        this.setState({
            allow_comments_for_owned_texts: allow_comments_for_owned_texts
        }, () => {
            axios({method: 'POST', url: '/users/allow_comments_for_owned_texts', data: data, responseType: 'json'})
                .then(function (response) {
                    beautiful_alert('Успешно обновлено')
                })
                .catch(function (error) {
                    let error_text = '';
                    if (error.response && error.response.data && (error.response.data.error || error.response.data.errors)) {
                        if (error.response.data.error) {
                            error_text = error.response.data.error;
                        } else if (error.response.data.errors) {
                            error_text = error.response.data.errors;
                        } else {
                            error_text = 'Неизвестная ошибка. Обратитесь к администратору.';
                        }
                    } else {
                        error_text = 'Неизвестная ошибка. Обратитесь к администратору.';
                    }
                    beautiful_alert(error_text);
                });
        });
    }

    handleToggleMakeFavoritesVisible(e) {
        let make_favorites_visible = e.target.checked;
        const self = this;
        let data = {
            authenticity_token: Functions.getMetaContent("csrf-token"),
            user_id: this.props.user_id,
            make_favorites_visible: make_favorites_visible
        };
        this.setState({
            make_favorites_visible: make_favorites_visible
        }, () => {
            axios({method: 'POST', url: '/users/make_favorites_visible', data: data, responseType: 'json'})
                .then(function (response) {
                    beautiful_alert('Успешно обновлено')
                })
                .catch(function (error) {
                    let error_text = '';
                    if (error.response && error.response.data && (error.response.data.error || error.response.data.errors)) {
                        if (error.response.data.error) {
                            error_text = error.response.data.error;
                        } else if (error.response.data.errors) {
                            error_text = error.response.data.errors;
                        } else {
                            error_text = 'Неизвестная ошибка. Обратитесь к администратору.';
                        }
                    } else {
                        error_text = 'Неизвестная ошибка. Обратитесь к администратору.';
                    }
                    beautiful_alert(error_text);
                });
        });
    }

    handleToggleCommentsBan(e) {
        let comments_enabled = !e.target.checked;
        const self = this;
        let data = {
            authenticity_token: Functions.getMetaContent("csrf-token"),
            user_id: this.props.user_id,
            comments_banned: !comments_enabled
        };
        this.setState({
            comments_enabled: comments_enabled
        }, () => {
            axios({method: 'POST', url: '/users/toggle_comments_ban', data: data, responseType: 'json'})
                .then(function (response) {
                    beautiful_alert('Успешно обновлено')
                })
                .catch(function (error) {
                    let error_text = '';
                    if (error.response && error.response.data && (error.response.data.error || error.response.data.errors)) {
                        if (error.response.data.error) {
                            error_text = error.response.data.error;
                        } else if (error.response.data.errors) {
                            error_text = error.response.data.errors;
                        } else {
                            error_text = 'Неизвестная ошибка. Обратитесь к администратору.';
                        }
                    } else {
                        error_text = 'Неизвестная ошибка. Обратитесь к администратору.';
                    }
                    beautiful_alert(error_text);
                });
        });
    }

    handlePasswordChange(e) {
        e.preventDefault();
        let path = '/users/password';
        let method = 'POST';
        let data = {
            authenticity_token: Functions.getMetaContent("csrf-token"),
            user: {
                email: this.props.user_email
            }
        };
        const self = this;
        axios({method: method, url: path, data: data, responseType: 'json'})
            .then(function (response) {
                beautiful_alert('На вашу почту отправлено письмо для сброса пароля')
            })
            .catch(function (error) {
                let error_text = '';
                if (error.response && error.response.data && (error.response.data.error || error.response.data.errors)) {
                    if (error.response.data.error) {
                        error_text = error.response.data.error;
                    } else if (error.response.data.errors) {
                        error_text = error.response.data.errors;
                    } else {
                        error_text = 'Неизвестная ошибка. Обратитесь к администратору.';
                    }
                } else {
                    error_text = 'Неизвестная ошибка. Обратитесь к администратору.';
                }
                beautiful_alert(error_text);
            });
    }

    handleChange(e) {
        const target = e.target;
        let name, value;
        if (target) {
            name = target.name;
            if (name === 'avatar') {
                value = target.files[0];
                this.imageControlTextRef.current.innerHTML = target.files[0].name;
            } else {
                value = target.value;
            }
        } else {
            value = e.detail.value;
            name = e.detail.name;
        }
        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        let path = "/users/" + String(this.props.user_id);
        let method = 'PATCH';

        const formData = new FormData();
        formData.append('authenticity_token', Functions.getMetaContent("csrf-token"));

        ['ficbook', 'diary', 'ao3', 'twitter', 'avatar'].forEach((key) => {
            if (this.state[key]) {
                formData.append(key, this.state[key]);
            }
        });
        if (this.state.user_email) {
            formData.append('email', this.state.user_email);
        }
        if (this.state.html) {
            formData.append('about', this.state.html);
        }
        if (this.state.user_name) {
            formData.append('name', this.state.user_name);
        }

        const self = this;

        axios({method: method, url: path, data: formData, responseType: 'json', headers: {
                'content-type': 'multipart/form-data'
            }})
            .then(function (response) {
                window.location.reload();
            })
            .catch(function (error) {
                let error_text = '';
                if (error.response && error.response.data && (error.response.data.error || error.response.data.errors)) {
                    if (error.response.data.error) {
                        error_text = error.response.data.error;
                    } else if (error.response.data.errors) {
                        error_text = error.response.data.errors;
                    } else {
                        error_text = 'Неизвестная ошибка. Обратитесь к администратору.';
                    }
                } else {
                    error_text = 'Неизвестная ошибка. Обратитесь к администратору.';
                }
                beautiful_alert(error_text);
            });
    }

    render() {
        let links = Object.keys(this.props.user_links || {}).map((key) => <a key={key} target="_blank" href={this.props.user_links[key]}>{key}</a>);
        return (
            <React.Fragment>
                <form name="profile">
                    <div id="profile-avatar" style={this.state.user_avatar_src ? {backgroundImage: "url(" + this.state.user_avatar_src + ")"} : {}}></div>
                    {
                        this.props.is_editable &&
                        <div id="edit1" className="hidden-area">
                            <label id="avatar-upload" className="search-inputs"><span id="pic-status" ref={this.imageControlTextRef}>загрузите аватар</span>
                                <input type="file" name="avatar" accept="image/*" onChange={this.handleChange} /><br/>
                            </label>
                        </div>
                    }
                    <span className="p-info profile-user-name">{this.props.user_name}</span>
                    {
                        this.props.is_editable &&
                        <div id="edit5" className="hidden-area"><input id="edit-login" className="search-inputs profile-inputs" type="user_name" name="user_name"
                               placeholder="новый логин" autoComplete="off" value={this.state.user_name}  onChange={this.handleChange}/></div>
                    }

                    {
                        this.props.user_email &&
                        <span className="p-info"><a href={"mailto:" + this.props.user_email} id="profile-mail">{this.props.user_email}</a></span>
                    }
                    {
                        this.props.show_message_link &&
                        <span className="p-info"><a href={"/messages/new?target=" + this.props.user_name} id="profile-message">{"написать сообщение через сайт"}</a></span>
                    }
                    {
                        this.props.is_editable &&
                        <div id="edit2" className="hidden-area">
                            <input id="edit-mail" className="search-inputs profile-inputs" type="text" name="user_email"
                                   placeholder="новый e-mail" autoComplete="off" value={this.state.user_email} onChange={this.handleChange} />
                            <span className="p-info"><a href="" onClick={this.handlePasswordChange}>{"отправить запрос на смену пароля"}</a></span>

                        </div>
                    }

                    <span className="p-info">
                        {links}
                    </span>
                    {
                        this.props.is_editable &&
                        <div id="edit3" className="hidden-area">
                            <input id="profile-link0" className="search-inputs profile-inputs social-link" type="text" name="twitter"
                                   placeholder="ссылка на ваш twitter" autoComplete="off" value={this.state.twitter} onChange={this.handleChange} />
                            <input id="profile-link1" className="search-inputs profile-inputs social-link" type="text" name="ficbook"
                                   placeholder="ссылка на ваш ficbook" autoComplete="off" value={this.state.ficbook} onChange={this.handleChange} />
                            <input id="profile-link2" className="search-inputs profile-inputs social-link" type="text" name="diary"
                                   placeholder="ссылка на ваш diary" autoComplete="off" value={this.state.diary} onChange={this.handleChange} />
                            <input id="profile-link3" className="search-inputs profile-inputs social-link" type="text" name="ao3"
                                   placeholder="ссылка на ваш AO3" autoComplete="off" value={this.state.ao3} onChange={this.handleChange} />
                        </div>
                    }

                    {/* этот текст валидируется и фильтруется с бэка как весь остальной юзер инпут */}
                    <span id="profile-info" className="p-info" dangerouslySetInnerHTML={{__html: this.props.user_about}}></span>

                    {
                        this.props.is_editable && <React.Fragment>
                            <div id="edit4" className="hidden-area">
                            {/*<textarea id="edit-profile-info" className="search-inputs" type="text" name="user_about"*/}
                                      {/*placeholder="напишите несколько слов о себе" autoComplete="off" value={this.state.user_about} onChange={this.handleChange}/>*/}
                            <TextInputWithButtons edit_icons_class="user_profile_text_input_icons" inner_id="edit-profile-info" inner_class="search-inputs" name="html" text={this.state.html} onTextChange={this.handleChange} placeholder="напишите несколько слов о себе" />

                            <div id="save-info-div"><input id="save-info" className="bttn-dark" type="submit"
                                                           value="сохранить" onClick={this.handleSubmit}/></div>
                            </div>
                            <div className={'user-profile-checkboxes'}>
                            <span id="checkbox-make-favorites-visible" className="p-info"><input type="checkbox" id="make_favorites_visible"
                                                            checked={this.state.make_favorites_visible}
                                                            onChange={this.handleToggleMakeFavoritesVisible}/>
                            <label htmlFor="make_favorites_visible">Сделать мое избранное видимым для всех остальных</label></span>

                            <span id="checkbox-allow-comment" className="p-info"><input type="checkbox" id="allow_comments_for_owned_texts" checked={this.state.allow_comments_for_owned_texts} onChange={this.handleToggleAllowCommentsForOwnedTexts}/>
                            <label htmlFor="allow_comments_for_owned_texts">Разрешить комментарии к моим текстам</label></span>
                            {
                                this.props.comments_bannable &&
                                <span className="p-info" id="checkbox-ban-comment"><input type="checkbox" id="ban_comments" checked={!this.state.comments_enabled} onChange={this.handleToggleCommentsBan}/>
                            <label htmlFor="ban_comments">Запретить пользователю возможность комментировать</label></span>
                            }
                        </div></React.Fragment>

                    }
                </form>

                {
                    this.props.show_registration_info &&
                        <div id="registration-info">
                            Группа: <span id="group">{this.props.group}</span><br/>
                            Зарегистрирован: <span id="registration">{this.props.registration}</span><br/>
                            Последний визит: <span id="last-session">{this.props.last_session}</span><br/>
                            Комментарии: <span id="comments-enabled">{this.props.comments_enabled ? 'разрешены' : 'запрещены'}</span>
                        </div>
                }

                {
                    this.props.show_registration_info_only_time &&
                    <div id="registration-info">
                        Зарегистрирован: <span id="registration">{this.props.registration}</span>
                    </div>
                }

                {
                    this.props.is_editable &&
                    <div id="profile-edit">
                        <span id="profile-edit-button">редактировать</span>
                        {
                            this.props.is_deletable &&
                            <ActionWithConfirmation image_id="profile-del-button"
                                                    text_instead_of_button={true}
                                                    text={"удалить"}
                                                    image_title='удалить аккаунт' request_method='DELETE'
                                                    request_path={'/users/' + String(this.props.user_id)}
                                                    cancel_text='нет' yes_text='да'
                                                    confirmation_text='Вы действительно хотите удалить аккаунт?'
                                                    redirect_after_path={this.props.root_url} />
                        }
                    </div>
                }
            </React.Fragment>
        )
    };
}

UserProfile.propTypes = {
    user_name: PropTypes.string,
    user_email: PropTypes.string,
    user_id: PropTypes.number,
    user_about: PropTypes.string,
    user_avatar_src: PropTypes.string,
    show_registration_info: PropTypes.bool,
    show_registration_info_only_time: PropTypes.bool,
    is_editable: PropTypes.bool,
    is_deletable: PropTypes.bool,
    registration: PropTypes.string,
    last_session: PropTypes.string,
    group: PropTypes.string,
    user_links: PropTypes.object,
    root_url: PropTypes.string,
    comments_enabled: PropTypes.bool,
    comments_bannable: PropTypes.bool,
    make_favorites_visible: PropTypes.bool,
    allow_comments_for_owned_texts: PropTypes.bool,
    show_message_link: PropTypes.bool,
    is_self: PropTypes.bool
};

export default UserProfile