import React from "react"
import PropTypes from "prop-types";
import TextInputWithButtons from "./TextInputWithButtons";

const Functions = require('./functions.js');
const axios = require('axios').default;

class NewsForm extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.closeError = this.closeError.bind(this);
        this.showError = this.showError.bind(this);
        this.state = {
            title: this.props.title,
            tags: this.props.tags,
            html: this.props.text
        };
        this.errorsRef = React.createRef();
        this.errorsTextRef = React.createRef();
    }

    handleChange(e) {
        const target = e.target;
        let name, value;
        if (target) {
            name = target.name;
            value = target.value;
        } else {
            value = e.detail.value;
            name = e.detail.name;
        }
        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        let path = '';
        let method = '';
        if (this.props.id) {
            path = '/news/' + String(this.props.id);
            method = 'PATCH';
        } else {
            path = '/news';
            method = 'POST';
        }
        let data = {
            authenticity_token: Functions.getMetaContent("csrf-token"),
            title: this.state.title,
            tags: this.state.tags,
            text: this.state.html
        };

        const self = this;

        axios({method: method, url: path, data: data, responseType: 'json'})
            .then(function (response) {
                window.location.href = '/news';
            })
            .catch(function (error) {
                let error_text = '';
                if (error.response && error.response.data && (error.response.data.error || error.response.data.errors)) {
                    if (error.response.data.error) {
                        error_text = error.response.data.error;
                    } else if (error.response.data.errors) {
                        error_text = error.response.data.errors;
                    } else {
                        error_text = 'Неизвестная ошибка. Обратитесь к администратору.';
                    }
                } else {
                    error_text = 'Неизвестная ошибка. Обратитесь к администратору.';
                }
                self.showError(error_text);
            });
    }

    showError(text) {
        this.errorsRef.current.style.display = 'block';
        this.errorsTextRef.current.innerHTML = text;
    }

    closeError() {
        this.errorsRef.current.style.display = 'none';
    }

    render() {
        let title = '';
        if (this.props.id) {
            title = 'Редактирование новости';
        } else {
            title = 'Создание новости';
        }
        return (
            <React.Fragment>
                <div id="all-news-block">
                    <h2 id="static_page_title">{title}</h2>
                    <div id="edit-news-block-div">
                        <form id="edit-news" action="POST">

                            <textarea id="edit-news-title" name="title" value={this.state.title || ''} onChange={this.handleChange} />

                            <TextInputWithButtons name="html" text={this.state.html} onTextChange={this.handleChange} />

                            <textarea placeholder="тэги через точку с запятой" id="edit-news-tags" name="tags" value={this.state.tags || ''} onChange={this.handleChange} />

                            <div className="news-error-sign" ref={this.errorsRef}>
                                <span id="close-sign3" onClick={this.closeError}>&times;</span>
                                <div id="news-form-error" ref={this.errorsTextRef}></div>
                            </div>

                            <span id="edit-news-go" className="bttn-dark bttn" onClick={this.handleSubmit}>подтвердить</span>
                        </form>
                    </div>
                </div>

            </React.Fragment>
        );
    }


}

NewsForm.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    tags: PropTypes.string,
    id: PropTypes.number
};

export default NewsForm