import React from "react"
import PropTypes from "prop-types";

const Functions = require('./functions.js');
const axios = require('axios').default;
import {beautiful_alert} from "../plain_js/layout.js";

class JuryNote extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            text: this.props.text
        };
        this.clickNote = this.clickNote.bind(this);
        this.closeNote = this.closeNote.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
        this.makeRequest = this.makeRequest.bind(this);

        this.noteRef = React.createRef();
    }

    clickNote(event) {
        event.preventDefault();
        let show = false;
        if (this.noteRef.current.style.display !== 'block') {
            show = true;
        }
        Array.from(document.getElementsByClassName("jury-note-js")).forEach((item) => {
            item.style.display = 'none';
        });
        if (show) {
            this.noteRef.current.style.display = 'block';
        } else {
            this.makeRequest();
        }
    }

    closeNote(event) {
        event.preventDefault();
        this.noteRef.current.style.display = 'none';
        this.makeRequest(); //сохраняем по закрытию на всякий случай
    }

    onTextChange(event) {
        this.setState({text: event.target.value}, this.makeRequest);
    }

    makeRequest() {
        let path = '/users/save_jury_note';
        let method = 'POST';

        let data = {
            authenticity_token: Functions.getMetaContent("csrf-token"),
            text: this.state.text,
            work_id: this.props.work_id,
            user_id: this.props.user_id
        };


        const self = this;

        axios({method: method, url: path, data: data, responseType: 'json'})
            .then(function (response) {
                let event = new CustomEvent("input", { bubbles: true, 'detail': {name: self.props.name, value: self.state.text} });
                if (self.props.onTextChange) {
                    self.props.onTextChange(event);
                }
            })
            .catch(function (error) {
                console.log(error);
                console.log(error.response);
                let error_text = '';
                if (error.response && error.response.data && (error.response.data.error || error.response.data.errors)) {
                    if (error.response.data.error) {
                        error_text = error.response.data.error;
                    } else if (error.response.data.errors) {
                        error_text = error.response.data.errors;
                    } else {
                        error_text = 'Ошибка сохранения заметки.';
                    }
                } else {
                    error_text = 'Ошибка сохранения заметки.';
                }
                beautiful_alert(error_text);
            });
    }

    render () {
        return (
            <React.Fragment>
                {this.props.show_support_elements && <img className="open-note" src={this.props.icon_url} onClick={this.clickNote}/>}
                <div className={this.props.class_name + " jury-note-js"} ref={this.noteRef}>
                    {this.props.show_support_elements && <div className="close-note" onClick={this.closeNote}>&times;</div>}
                    <textarea name={'text'} onChange={this.onTextChange} value={this.state.text || ''} placeholder={"Здесь вы можете оставить заметку о тексте"}/>
                </div>
                {this.props.show_text && <div className="jury-note-show-text">{this.state.text}</div>}
            </React.Fragment>
        )
    }


}

JuryNote.propTypes = {
    text: PropTypes.string,
    name: PropTypes.string,
    icon_url: PropTypes.string,
    class_name: PropTypes.string,
    work_id: PropTypes.number,
    user_id: PropTypes.number,
    onTextChange: PropTypes.func,
    show_text: PropTypes.bool,
    show_support_elements: PropTypes.bool,
};

export default JuryNote