import PropTypes from "prop-types";

const Functions = require('./functions.js');
const axios = require('axios').default;
import {beautiful_alert} from "../plain_js/layout.js";

import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import JuryNote from "./JuryNote";

// часть кода отсюда https://codesandbox.io/s/ql08j35j3q


const isLeft = (droppableId) => {
    return droppableId.startsWith('droppable-left-');
};

const cellIndex = (droppableId) => {
    let list = droppableId.split('-');
    return Number(list[list.length - 1]);
};

// одна из главных функций, тут лежит существенная часть логики, почти вся логика меняется тут
const handleDrop = (old_list_from, old_list_to, drop_index, elem, from_index) => {
    let new_list_from = [];
    let new_list_to = [];
    const total_count = old_list_from.length;

    // если элемент попадает на пустое место, то просто вставляем и не парим себе мозги;
    // если нет, то элемент вставляем, а всё остальное сдвигаем до ближайшего пустого места вниз, если место не последнее, тогда вверх

    if (old_list_to[drop_index]) {
        // элемент попадает на другой элемент
        new_list_to = [...old_list_to];
        new_list_to[drop_index] = elem;

        // а остальное сдвигаем, в зависимости от ситуации

        if (old_list_from === old_list_to) {
            if (drop_index === from_index) {
                // просто элемент не сдвинулся, делать ничего не надо
            } else {
                // пытаемся сдвинуть элементы вниз (до пустой ячейки или того места, откуда мы взяли элемент),
                // если не получается, то вверх (до пустой ячейки или того места, откуда мы взяли элемент)

                // ищем пустую ячейку ниже дроп индекса
                let empty_index = null;
                for (let i = drop_index + 1; i < total_count; i++) {
                    if (old_list_to[i] === null || i === from_index) {
                        empty_index = i;
                        break;
                    }
                }
                if (empty_index) {
                    new_list_to[from_index] = null;
                    // если есть место сдвигать вниз, двигаем вниз
                    for (let i = drop_index + 1; i <= empty_index; i++) {
                        new_list_to[i] = old_list_to[i - 1];
                    }
                } else {
                    // двигаем вверх, ищем свободную ячейку вверху
                    for (let i = drop_index - 1; i >= 0; i--) {
                        if (old_list_to[i] === null || i === from_index) {
                            empty_index = i;
                            break;
                        }
                    }
                    new_list_to[from_index] = null;
                    for (let i = empty_index; i < drop_index; i++) {
                        new_list_to[i] = old_list_to[i + 1];
                    }
                }

            }
            new_list_from = [...new_list_to];
        } else {
            // ищем пустую ячейку ниже дроп индекса
            let empty_index = null;
            for (let i = drop_index + 1; i < total_count; i++) {
                if (old_list_to[i] === null) {
                    empty_index = i;
                    break;
                }
            }
            if (empty_index) {
                // если есть место сдвигать вниз, двигаем вниз
                for (let i = drop_index + 1; i <= empty_index; i++) {
                    new_list_to[i] = old_list_to[i - 1];
                }
            } else {
                // двигаем вверх, ищем свободную ячейку вверху
                for (let i = drop_index - 1; i >= 0; i--) {
                    if (old_list_to[i] === null) {
                        empty_index = i;
                        break;
                    }
                }
                for (let i = empty_index; i < drop_index; i++) {
                    new_list_to[i] = old_list_to[i + 1];
                }
            }
            new_list_from = [...old_list_from];
            new_list_from[from_index] = null;
        }
    } else {
        // элемент попадает на пустое место
        new_list_to = [...old_list_to];
        new_list_to[drop_index] = elem;
        if (old_list_from === old_list_to) {
            new_list_to[from_index] = null;
            new_list_from = [...new_list_to];
        } else {
            new_list_from = [...old_list_from];
            new_list_from[from_index] = null;
        }
    }

    return {new_list_from: new_list_from, new_list_to: new_list_to}
};

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',

    ...draggableStyle
});

class SortNominationWidget extends React.Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCancelSubmit = this.handleCancelSubmit.bind(this);
        this.disableDragNDrop = this.disableDragNDrop.bind(this);
        this.onNoteChange = this.onNoteChange.bind(this);
        let sorted_works = [], unsorted_works = [];
        const total_count = Object.keys((this.props.sorted_works || {})).length + (this.props.unsorted_works || []).length;
        if (this.props.sorted_works && Object.keys(this.props.sorted_works).length > 0) {
            for (let i = 0; i < total_count; i++) {
                if (this.props.sorted_works[i]) {
                    sorted_works.push(this.props.sorted_works[i]);
                } else {
                    sorted_works.push(null);
                }
            }
            unsorted_works = [...this.props.unsorted_works];
            while (unsorted_works.length < total_count) {
                unsorted_works.push(null);
            }
        } else {
            unsorted_works = [...this.props.unsorted_works];
            sorted_works = new Array(this.props.unsorted_works.length).fill(null);
        }
        this.state = {
            sorted_works: sorted_works,
            unsorted_works: unsorted_works,
            total_works: Object.keys((this.props.sorted_works || {})).length + (this.props.unsorted_works || []).length,
            drag_disabled: this.props.drag_disabled
        };
        this.cancelRef = React.createRef();
        this.submitRef = React.createRef();
    }

    onNoteChange(event) {
        let name = event.detail.name;
        if (name.startsWith('sorted_')) {
            let index = Number(name.replace('sorted_', ''));
            let new_sorted_works = this.state.sorted_works;
            new_sorted_works[index].note_text = event.detail.value;
            this.setState({sorted_works: new_sorted_works});
        } else {
            let index = Number(name.replace('unsorted_', ''));
            let new_unsorted_works = this.state.unsorted_works;
            new_unsorted_works[index].note_text = event.detail.value;
            this.setState({unsorted_works: new_unsorted_works});
        }
    }

    disableDragNDrop() {
        this.setState({
            drag_disabled: true
        });
    }

    enableDragNDrop() {
        this.setState({
            drag_disabled: false
        });
    }

    // тут ещё часть логики перетаскивания
    onDragEnd = result => {
        const { source, destination } = result;
        // dropped outside the list
        if (!destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            //та же ячейка, ничего не делаем
        } else {
            let old_list_from, old_list_to;

            if (isLeft(source.droppableId)) {
                old_list_from = this.state.unsorted_works;
            } else {
                old_list_from = this.state.sorted_works;
            }

            if (isLeft(destination.droppableId)) {
                old_list_to = this.state.unsorted_works;
            } else {
                old_list_to = this.state.sorted_works;
            }

            const drop_index = cellIndex(destination.droppableId);
            const elem = old_list_from[cellIndex(source.droppableId)];
            const from_index = cellIndex(source.droppableId);
            const result = handleDrop(old_list_from, old_list_to, drop_index, elem, from_index);

            let unsorted_works, sorted_works;
            if (isLeft(source.droppableId)) {
                unsorted_works = result.new_list_from;
                if (isLeft(destination.droppableId)) {
                    sorted_works = this.state.sorted_works;
                } else {
                    // if (result.new_list_to.length < this.state.total_count) {
                    //     console.log('HERE666');
                    // }
                    sorted_works = result.new_list_to;
                }
            } else {
                sorted_works = result.new_list_from;
                if (isLeft(destination.droppableId)) {
                    // if (result.new_list_to.length < this.state.total_count) {
                    //     console.log('HERE666');
                    // }
                    unsorted_works = result.new_list_to;
                } else {
                    unsorted_works = this.state.unsorted_works;
                }
            }

            const self = this;

            this.setState({
                sorted_works: sorted_works,
                unsorted_works: unsorted_works
            }, () => {

            // а это сохраним промежуточный результат если получится
            let path = self.props.intermediate_path;
            let method = 'POST';

            // console.log(self.state.sorted_works);

            let sorted_works_to_send = [];

            for (let i = 0; i < this.state.total_count; i++) {
                if (sorted_works[i]) {
                    sorted_works_to_send.push(sorted_works[i]);
                } else {
                    sorted_works_to_send.push({id: null});
                }
            }

            let data = {
                authenticity_token: Functions.getMetaContent("csrf-token"),
                is_fap: self.props.is_fap,
                sorted_works: self.state.sorted_works.map((w) => (w ? w : {id: null})),
                nomination_id: self.props.nomination_id
            };

            axios({method: method, url: path, data: data, responseType: 'json'})
                .then(function (response) {

                })
                .catch(function (error) {
                    let error_text = '';
                    if (error.response && error.response.data && (error.response.data.error || error.response.data.errors)) {
                        if (error.response.data.error) {
                            error_text = error.response.data.error;
                        } else if (error.response.data.errors) {
                            error_text = error.response.data.errors;
                        } else {
                            error_text = 'Ошибка сохранения промежуточного результата.';
                        }
                    } else {
                        error_text = 'Ошибка сохранения промежуточного результата.';
                    }
                    beautiful_alert(error_text);
                });
            });
        }
    };

    handleCancelSubmit(event) {
        event.preventDefault();

        let path = this.props.cancel_path;
        let method = 'POST';

        let data = {
            authenticity_token: Functions.getMetaContent("csrf-token"),
            is_fap: this.props.is_fap,
            nomination_id: this.props.nomination_id
        };

        const self = this;

        axios({method: method, url: path, data: data, responseType: 'json'})
            .then(function (response) {
                self.enableDragNDrop();
                beautiful_alert('Вы снова можете менять порядок работ для номинации '+ self.props.nomination_title);
            })
            .catch(function (error) {
                let error_text = '';
                if (error.response && error.response.data && (error.response.data.error || error.response.data.errors)) {
                    if (error.response.data.error) {
                        error_text = error.response.data.error;
                    } else if (error.response.data.errors) {
                        error_text = error.response.data.errors;
                    } else {
                        error_text = 'Неизвестная ошибка. Обратитесь к администратору.';
                    }
                } else {
                    error_text = 'Неизвестная ошибка. Обратитесь к администратору.';
                }
                beautiful_alert(error_text);
            });
    }

    handleSubmit(event) {
        event.preventDefault();

        let path = this.props.submit_path;
        let method = 'POST';

        let data = {
            authenticity_token: Functions.getMetaContent("csrf-token"),
            sorted_works: this.state.sorted_works,
            is_fap: this.props.is_fap,
            nomination_id: this.props.nomination_id
        };

        const self = this;

        axios({method: method, url: path, data: data, responseType: 'json'})
            .then(function (response) {
                self.disableDragNDrop();
                beautiful_alert('Выбранный порядок работ для номинации '+ self.props.nomination_title + ' сохранен');
            })
            .catch(function (error) {
                let error_text = '';
                if (error.response && error.response.data && (error.response.data.error || error.response.data.errors)) {
                    if (error.response.data.error) {
                        error_text = error.response.data.error;
                    } else if (error.response.data.errors) {
                        error_text = error.response.data.errors;
                    } else {
                        error_text = 'Неизвестная ошибка. Обратитесь к администратору.';
                    }
                } else {
                    error_text = 'Неизвестная ошибка. Обратитесь к администратору.';
                }
                beautiful_alert(error_text);
            });
    }

    render() {
        return (
            <React.Fragment>
                    <span className="profile-nominname"><b>&bull;</b>{this.props.nomination_title}</span>
                    <div className="sorting">
                        <DragDropContext onDragEnd={this.onDragEnd}>
                                <div id="sorting-right" align="right">
                                    { Array.from({length:this.state.total_works},(v,k)=>k+1).map((_, index) => (
                                        <Droppable droppableId={"droppable-right-" + String(index)} key={"droppable-right-" + String(index)}>
                                            {(provided, snapshot) => (
                                                <div className="cell" ref={provided.innerRef}>
                                                    {this.state.sorted_works[index] &&
                                                    <Draggable
                                                        key={String(this.state.sorted_works[index].id)}
                                                        draggableId={String(this.state.sorted_works[index].id)}
                                                        index={index}
                                                        isDragDisabled={this.state.drag_disabled}
                                                    >
                                                        {(provided, snapshot) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                style={getItemStyle(
                                                                    snapshot.isDragging,
                                                                    provided.draggableProps.style
                                                                )}>
                                                                <div className="unnumbered numbered">
                                                                    <JuryNote icon_url={"/images/icons/note_black.png"}
                                                                              user_id={this.props.user_id} work_id={this.state.sorted_works[index].id}
                                                                              text={this.state.sorted_works[index].note_text} name={'sorted_' + String(index)}
                                                                              onTextChange={this.onNoteChange} show_text={false} show_support_elements={true}
                                                                              class_name={"jury-note"} />
                                                                    <a target="_blank" href={"/works/" + String(this.state.sorted_works[index].id)}>{"(id: " + String(this.state.sorted_works[index].id) + ") " + this.state.sorted_works[index].title}</a>
                                                                    <div className="hand-icon">
                                                                        <img src="/images/icons/hand3.png"/>
                                                                    </div>
                                                                </div>
                                                            </div>)}
                                                    </Draggable>}
                                                    {String(index + 1).padStart(2, 0)}
                                                    {provided.placeholder}
                                                </div>)}

                                        </Droppable>
                                    ))}
                                </div>

                                <div id="sorting-left">

                                    {Array.from({length:this.state.total_works},(v,k)=>k+1).map((_, index) =>
                                <Droppable droppableId={"droppable-left-" + String(index)} key={"droppable-left-" + String(index)}>
                                    {(provided, snapshot) => (
                                    <div className="left-cell" ref={provided.innerRef}>
                                        { this.state.unsorted_works[index] && <Draggable
                                            key={String(this.state.unsorted_works[index].id)}
                                            draggableId={String(this.state.unsorted_works[index].id)}
                                            index={index}
                                            isDragDisabled={this.state.drag_disabled}
                                        >
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}>
                                        <div className="unnumbered">
                                            <JuryNote icon_url={"/images/icons/note_black.png"} user_id={this.props.user_id}
                                                      work_id={this.state.unsorted_works[index].id} text={this.state.unsorted_works[index].note_text}
                                                      name={'unsorted_' + String(index)} onTextChange={this.onNoteChange} show_text={false}
                                                      show_support_elements={true} class_name={"jury-note"}/>

                                            <a target="_blank" href={"/works/" + String(this.state.unsorted_works[index].id)}>{"(id: " + String(this.state.unsorted_works[index].id) + ") " + this.state.unsorted_works[index].title}</a>
                                            <div className="hand-icon">
                                                <img src="/images/icons/hand3.png"/>
                                            </div>
                                        </div>
                                    </div>
                                            )}
                                        </Draggable>}
                                        {provided.placeholder}
                                    </div>)}</Droppable> )}</div>
                        </DragDropContext>
                        <div id="save-cancel">
                            <input style={this.state.drag_disabled ? {display:'inline-block'} : {display:'none'}} ref={this.cancelRef} id="cancel-numbered" className="bttn-dark" type="submit" value="отменить" onClick={this.handleCancelSubmit}/>
                            <input style={!this.state.drag_disabled && this.state.unsorted_works.filter(x => x).length === 0 ? {display:'inline-block'} : {display:'none'}} ref={this.submitRef} id="save-numbered" className="bttn-dark" type="submit" value="сохранить" onClick={this.handleSubmit}/>
                        </div>
                    </div>
            </React.Fragment>
        )
    };
}

SortNominationWidget.propTypes = {
    unsorted_works: PropTypes.array,
    sorted_works: PropTypes.object,
    drag_disabled: PropTypes.bool,
    submit_path: PropTypes.string,
    cancel_path: PropTypes.string,
    intermediate_path: PropTypes.string,
    nomination_title: PropTypes.string,
    is_fap: PropTypes.bool,
    nomination_id: PropTypes.number,
    user_id: PropTypes.number

};

export default SortNominationWidget