import React from "react"
import PropTypes from "prop-types"
import Recaptcha from 'react-grecaptcha';


const Functions = require('./functions.js');
const axios = require('axios').default;

class UserAuthBlock extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            login_mail: '',
            login_password: '',
            reg_mail: '',
            reg_password: '',
            reg_password_confirmation: '',
            reg_name: '',
            rec_mail: '',
            reconfirm_mail: ''
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.showError = this.showError.bind(this);
        this.showNote = this.showNote.bind(this);
        this.toInbox = this.toInbox.bind(this);
    }

    toInbox(event) {
        event.preventDefault();
        if (window.location.pathname === '/users/' + String(this.props.user.id)) {
            window.location.hash = '#mail';
            window.location.reload();
        } else {
            window.location.href = '/users/' + String(this.props.user.id) + '#mail';
        }
    }

    closeError() {
        document.getElementById('sign1').style.display = 'none';
    }

    closeNote() {
        document.getElementById('sign2').style.display = 'none';
    }

    showError(text) {
        this.closeNote();
        document.getElementById('menu-error').textContent = text;
        document.getElementById('sign1').style.display = 'block';
    }

    showNote(text) {
        this.closeError();
        document.getElementById('menu-notice').textContent = text;
        document.getElementById('sign2').style.display = 'block';
    }

    handleSubmit(event) {
        event.preventDefault();
        const target_id = event.target.id;
        let path = '';
        let method = 'POST';
        let data = {
            authenticity_token: Functions.getMetaContent("csrf-token")
        };
        let refresh_on_success = true;
        let success_message = '';
        let failure_message = 'Неизвестная ошибка. Повторите действие позже или обратитесь к администратору.';

        switch (target_id) {
            case 'enter':
                path = '/users/sign_in';
                data['user'] = {
                    email: this.state.login_mail,
                    password: this.state.login_password
                };
                break;
            case 'reg':
                path = '/users';
                data['user'] = {
                    email: this.state.reg_mail,
                    password: this.state.reg_password,
                    password_confirmation: this.state.reg_password_confirmation,
                    name: this.state.reg_name
                };
                refresh_on_success = false;
                success_message = 'На почту, указанную при регистрации, было отправлено письмо для её подтверждения. Пройдите по ссылке из письма, чтобы продолжить. Если письмо не пришло в течение нескольких минут, проверьте папку "Спам".';
                break;
            case 'rec':
                path = '/users/password';
                data['user'] = {
                    email: this.state.rec_mail
                };
                refresh_on_success = false;
                success_message = 'На указанную почту было отправлено письмо для сброса пароля. Если письмо не пришло в течение нескольких минут, проверьте папку "Спам".';
                break;
            case 'reconfirm':
                path = '/users/confirmations';
                data['user'] = {
                    email: this.state.reconfirm_mail
                };
                refresh_on_success = false;
                success_message = 'На указанную почту было отправлено письмо для повторного подтверждения адреса. Если письмо не пришло в течение нескольких минут, проверьте папку "Спам".';
                break;
            case 'logout-link':
                path = '/users/sign_out';
                method = 'DELETE';
                break;
            default:
                this.showError(failure_message);
                break;
        }

        const self = this;

        if (window.grecaptcha) {
            window.grecaptcha.ready(function() {
                window.grecaptcha.execute(self.props.captcha_key, {action: 'submit'}).then(function(token) {
                    // window.grecaptcha.execute("6LfufuMZAAAAAOp8CZ76myc_rtM0JHw5EzxLrDs0", {action: 'submit'}).then(function(token) {
                    // Add your logic to submit to your backend server here.

                    data['token'] = token;

                    axios({method: method, url: path, data: data, responseType: 'json'})
                        .then(function (response) {
                            if (refresh_on_success) {
                                location.reload();
                            } else {
                                self.showNote(success_message);
                            }
                        })
                        .catch(function (error) {
                            let error_text = '';
                            if (error.response && error.response.data && (error.response.data.error || error.response.data.errors)) {
                                if (error.response.data.error) {
                                    error_text = error.response.data.error;
                                } else {
                                    if (error.response.data.errors.email) {
                                        error_text = 'Почта ' + error.response.data.errors.email[0];
                                    } else if (error.response.data.errors.password) {
                                        error_text = 'Пароль ' + error.response.data.errors.password[0];
                                    } else if (error.response.data.errors.password_confirmation) {
                                        error_text = 'Подтверждение пароля ' + error.response.data.errors.password_confirmation[0];
                                    } else if (error.response.data.errors.name) {
                                        error_text = 'Имя ' + error.response.data.errors.name[0];
                                    } else {
                                        error_text = failure_message;
                                    }
                                }
                            } else {
                                error_text = failure_message;
                            }
                            self.showError(error_text);
                        });
                });
            });
        } else {
            axios({method: method, url: path, data: data, responseType: 'json'})
                .then(function (response) {
                    if (refresh_on_success) {
                        location.reload();
                    } else {
                        self.showNote(success_message);
                    }
                })
                .catch(function (error) {
                    let error_text = '';
                    if (error.response && error.response.data && (error.response.data.error || error.response.data.errors)) {
                        if (error.response.data.error) {
                            error_text = error.response.data.error;
                        } else {
                            if (error.response.data.errors.email) {
                                error_text = 'Почта ' + error.response.data.errors.email[0];
                            } else if (error.response.data.errors.password) {
                                error_text = 'Пароль ' + error.response.data.errors.password[0];
                            } else if (error.response.data.errors.password_confirmation) {
                                error_text = 'Подтверждение пароля ' + error.response.data.errors.password_confirmation[0];
                            } else if (error.response.data.errors.name) {
                                error_text = 'Имя ' + error.response.data.errors.name[0];
                            } else {
                                error_text = failure_message;
                            }
                        }
                    } else {
                        error_text = failure_message;
                    }
                    self.showError(error_text);
                });
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    render () {
        if (this.props.user && this.props.user.id !== null) {
            const user_link = "/users/" + this.props.user.id;
            const operating_cabinet_link = "/users/" + this.props.user.id + "/operating_cabinet";
            return (
                <React.Fragment>
                    <div id="user-links">
                        <span><a id="profile-link" href={user_link}>личный кабинет</a>
                        {this.props.user.unread_messages_count > 0 &&
                        <span><a onClick={this.toInbox} href={'/users/' + String(this.props.user.id) + '#mail'}>{this.props.user.unread_messages_count}</a></span>}
                        {this.props.user.unread_messages_count === 0 && this.props.user.work_notifications_count > 0 &&
                        <span><a href={this.props.user.work_notifications_first_link}>{this.props.user.work_notifications_count}</a></span>}
                        {this.props.user.show_operating_cabinet && <a id="profile-link" href={operating_cabinet_link}>рабочий кабинет</a>}</span>
                        {
                            this.props.user['role'] === 'admin' &&
                            <a id="admin-panel-link" href="/admin">админка</a>
                        }
                        <a id="logout-link" href="/users/sign_out" onClick={this.handleSubmit}>выход</a>
                    </div>
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <div id="forms">
                        <a id="open-form1" href="#">вход</a>
                        <form id="enter" action="POST" onSubmit={this.handleSubmit}>
                            <input id="enter-mail" type="text" name="login_mail" value={this.state.login_mail}
                                   placeholder="mail" autoComplete="off" onChange={this.handleInputChange}/>
                            <input id="enter-pass" type="password" name="login_password"
                                   value={this.state.login_password} placeholder="pass" autoComplete="off"
                                   onChange={this.handleInputChange}/>
                            <input id="enter-ok" className="submit" type="submit" value="ok"/>
                        </form>
                        <a id="open-form2" href="#">регистрация</a>
                        <form id="reg" action="POST" onSubmit={this.handleSubmit}>
                            <input id="reg-mail" type="text" name="reg_mail" value={this.state.reg_mail}
                                   placeholder="mail" autoComplete="off" onChange={this.handleInputChange}/>
                            <input id="reg-pass" type="password" name="reg_password" value={this.state.reg_password}
                                   placeholder="pass" autoComplete="off" onChange={this.handleInputChange}/>
                            <input id="reg-pass-conf" type="password" name="reg_password_confirmation" value={this.state.reg_password_confirmation}
                                   placeholder="confirm pass" autoComplete="off" onChange={this.handleInputChange}/>
                            <input id="reg-name" type="text" name="reg_name" placeholder="name" autoComplete="off"
                                   onChange={this.handleInputChange}/>
                            <input id="reg-ok" className="submit" type="submit" value="ok"/>
                        </form>
                        <a id="open-form3" href="#">забыли пароль?</a>
                        <form id="rec" action="POST" onSubmit={this.handleSubmit}>
                            <input id="rec-mail" type="text" name="rec_mail" value={this.state.rec_mail}
                                   placeholder="mail" autoComplete="off" onChange={this.handleInputChange}/>
                            <input id="rec-ok" className="submit" type="submit" value="ok"/>
                        </form>
                        <a id="open-form4" href="#">подтвердить почту</a>
                        <form id="reconfirm" action="POST" onSubmit={this.handleSubmit}>
                            <input id="reconfirm-mail" type="text" name="reconfirm_mail" value={this.state.reconfirm_mail}
                                   placeholder="mail" autoComplete="off" onChange={this.handleInputChange}/>
                            <input id="reconfirm-ok" className="submit" type="submit" value="ok"/>
                        </form>
                    </div>
                    <div id="sign1" className="sign">
                        <a href="#" id="close-sign1">&times;</a>
                        <span id="menu-error"></span>
                    </div>
                    <div id="sign2" className="sign">
                        <a href="#" id="close-sign2">&times;</a>
                        <span id="menu-notice"></span>
                    </div>

                </React.Fragment>
            );
        }
    }
}

UserAuthBlock.propTypes = {
    user: PropTypes.object,
    captcha_key: PropTypes.string
};

export default UserAuthBlock
