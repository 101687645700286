import React from "react"
import PropTypes from "prop-types";

const Functions = require('./functions.js');
const axios = require('axios').default;
import {beautiful_alert} from "../plain_js/layout.js";
import ActionWithConfirmation from "./ActionWithConfirmation";

class ForumTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            entries: this.props.entries,
            current_page: 1,
            is_first: true,
            is_last: false,
            total_count: this.props.total_count,
            total_pages: this.props.total_pages
        };
        this.toFirstPage = this.toFirstPage.bind(this);
        this.toPrevPage = this.toPrevPage.bind(this);
        this.toNextPage = this.toNextPage.bind(this);
        this.toLastPage = this.toLastPage.bind(this);
        this.getData = this.getData.bind(this);
        this.gotoPage = this.gotoPage.bind(this);
    }

    gotoPage(event) {
        this.getData(Number(event.target.value));
    }

    toFirstPage(e) {
        e.preventDefault();
        if (e.target.className == 'pagination-link-inactive') {
            return;
        }
        this.getData(1);
    }

    toPrevPage(e) {
        e.preventDefault();
        if (e.target.className == 'pagination-link-inactive') {
            return;
        }
        this.getData(this.state.current_page - 1);
    }

    toNextPage(e) {
        e.preventDefault();
        if (e.target.className == 'pagination-link-inactive') {
            return;
        }
        this.getData(this.state.current_page + 1);
    }

    toLastPage(e) {
        e.preventDefault();
        if (e.target.className == 'pagination-link-inactive') {
            return;
        }
        this.getData(this.state.total_pages);
    }

    refreshData() {
        this.getData(1);
    }

    getData(page) {
        let path = this.props.data_path;
        let method = 'POST';

        let data = {
            authenticity_token: Functions.getMetaContent("csrf-token"),
            page: page,
            id: this.props.user_id
        };

        const self = this;

        axios({method: method, url: path, data: data, responseType: 'json'})
            .then(function (response) {
                let total_pages = self.state.total_pages;
                let total_count = self.state.total_count;
                if (response.data.total_pages == 0 || response.data.total_pages) {
                    total_pages = response.data.total_pages;
                }
                if (response.data.total_count == 0 || response.data.total_count) {
                    total_count = response.data.total_count;
                }
                self.setState({
                    current_page: page,
                    is_first: (page === 1),
                    is_last: (page === total_pages),
                    entries: response.data.entries,
                    total_pages: total_pages,
                    total_count: total_count
                });
            })
            .catch(function (error) {
                let error_text = '';
                if (error.response && error.response.data && (error.response.data.error || error.response.data.errors)) {
                    if (error.response.data.error) {
                        error_text = error.response.data.error;
                    } else if (error.response.data.errors) {
                        error_text = error.response.data.errors;
                    } else {
                        error_text = 'Неизвестная ошибка. Обратитесь к администратору.';
                    }
                } else {
                    error_text = 'Неизвестная ошибка. Обратитесь к администратору.';
                }
                beautiful_alert(error_text);
            });
    }


    render() {
        return (
            <React.Fragment>
                <div id={this.props.div_id} className="bookmark-content" style={{display:'none'}} >
                    <div className="overflow-div">
                        <table id="thread-table" className="profile-table">
                            <tbody>
                            {this.state.entries.map((entry) => <tr className={entry.is_new ? '' : 'read-thread'} key={entry.key}>
                                    <td className="thread-status"><img
                                        src={entry.is_new ? '/images/icons/thread_new.png' : '/images/icons/thread.png'}
                                        title={entry.is_new ? 'новое' : 'прочитанное'}/></td>
                                    <td className="ts">{entry.topicstarter_name}</td>
                                    <td className="thread-title"><a className="mail-link" href={entry.link}>{entry.title}</a></td>
                                    <td className="new-comments">{String(entry.total_count) + (entry.unread_count > 0 ? ' (' + String(entry.unread_count) + ' новых)' : '')}</td>
                                    <td className="pinned-column">{entry.pinned && <img src="/images/icons/pinned.png" />}</td>
                                    <td className="thread-datetime">{entry.last_comment_at}</td>
                                </tr>)}
                            </tbody>
                        </table>
                    </div>

                    { this.state.total_pages !== null && this.state.total_pages > 1 && this.state.total_count > 0 &&
                    <div className="user_works_content user-works-page-counter">
                        <a id="first-p" className={this.state.is_first ? 'pagination-link-inactive' : ''} href={''} onClick={this.toFirstPage}>&#171;</a>
                        <a id="previos-p" className={this.state.is_first ? 'pagination-link-inactive' : ''} href={''} onClick={this.toPrevPage}>&#8249;</a>
                        <span id='current-p'>{this.state.current_page}</span>
                        <a id="next-p" className={this.state.is_last ? 'pagination-link-inactive' : ''} href={''} onClick={this.toNextPage}>&#8250;</a>
                        <a id="last-p" className={this.state.is_last ? 'pagination-link-inactive' : ''} href={''} onClick={this.toLastPage}>&#187;</a>
                        <span>
                        <select className="goto-page" id="goto-page" name='goto_page' onChange={this.gotoPage} value={this.state.current_page}>
                            {Array.from({length: this.state.total_pages}, (_, i) => i + 1).map((item) =>
                                <option value={item} key={'gotopage_' + String(item)}>{item}</option>
                            )}
                        </select>
                        </span>
                    </div>}

                    { this.props.can_add_thread && <div id="thread-table-div">
                        <a href={this.props.new_thread_path}><input id="add-thread" className="bttn-dark" type="submit" value="новый тред"/></a>
                    </div> }
                </div>
            </React.Fragment>
        )
    }
}

ForumTable.propTypes = {
    entries: PropTypes.array,
    total_pages: PropTypes.number,
    total_count: PropTypes.number,
    user_id: PropTypes.number,
    div_id: PropTypes.string,
    data_path: PropTypes.string,
    new_thread_path: PropTypes.string,
    can_add_thread: PropTypes.bool
};

export default ForumTable