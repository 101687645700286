import React from "react"
import PropTypes from "prop-types";

const Functions = require('./functions.js');
const axios = require('axios').default;
import {beautiful_alert} from "../plain_js/layout.js";
import IconClickControl from "./IconClickControl";
import ActionWithConfirmation from "./ActionWithConfirmation";
import SelectInput from "./SelectInput";
import JuryNote from "./JuryNote";


class WorksProfileTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            entries: this.props.entries,
            current_page: 1,
            is_first: true,
            is_last: false,
            fandom_name: '',
            fandom_status: 0,
            total_count: this.props.total_count,
            total_pages: this.props.total_pages,
            add_fandom_value: '',
            character_name: '',
            character_status: 0,
            fandom_id: null,
            add_fandom_id: null,
            add_character_name: ''
        };
        this.toFirstPage = this.toFirstPage.bind(this);
        this.toPrevPage = this.toPrevPage.bind(this);
        this.toNextPage = this.toNextPage.bind(this);
        this.toLastPage = this.toLastPage.bind(this);
        this.getData = this.getData.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
        this.handleAddClick = this.handleAddClick.bind(this);
        this.reloadWorks = this.reloadWorks.bind(this);
        this.gotoPage = this.gotoPage.bind(this);
    }

    gotoPage(event) {
        this.getData(Number(event.target.value));
    }

    reloadWorks() {
        this.getData(this.state.current_page)
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleAddClick(e) {
        e.preventDefault();
        let method = 'POST';
        let path = this.props.add_path;
        const self = this;
        let data = {
            authenticity_token: Functions.getMetaContent("csrf-token"),
            name: this.state.add_fandom_value || this.state.add_character_name,
            fandom_id: this.state.add_fandom_id,
        };

        axios({method: method, url: path, data: data, responseType: 'json'})
            .then(function (response) {
                self.setState({current_page: 1, add_fandom_value: '', add_character_name: ''}, self.getData(1))
            })
            .catch(function (error) {
                let error_text = '';
                if (error.response && error.response.data && (error.response.data.error || error.response.data.errors)) {
                    if (error.response.data.error) {
                        error_text = error.response.data.error;
                    } else if (error.response.data.errors) {
                        error_text = error.response.data.errors;
                    } else {
                        error_text = 'Неизвестная ошибка. Обратитесь к администратору.';
                    }
                } else {
                    error_text = 'Неизвестная ошибка. Обратитесь к администратору.';
                }
                beautiful_alert(error_text);
            });
    }

    handleSearchSubmit(e) {
        e.preventDefault();
        this.setState({current_page: 1}, this.getData(this.state.current_page));
    }

    toFirstPage(e) {
        e.preventDefault();
        if (e.target.className == 'pagination-link-inactive') {
            return;
        }
        this.getData(1);
    }

    toPrevPage(e) {
        e.preventDefault();
        if (e.target.className == 'pagination-link-inactive') {
            return;
        }
        this.getData(this.state.current_page - 1);
    }

    toNextPage(e) {
        e.preventDefault();
        if (e.target.className == 'pagination-link-inactive') {
            return;
        }
        this.getData(this.state.current_page + 1);
    }

    toLastPage(e) {
        e.preventDefault();
        if (e.target.className == 'pagination-link-inactive') {
            return;
        }
        this.getData(this.state.total_pages);
    }

    getData(page) {
        let path = this.props.data_path;
        let method = 'POST';

        let data = {
            authenticity_token: Functions.getMetaContent("csrf-token"),
            page: page,
            fandom_name: this.state.fandom_name,
            fandom_status: this.state.fandom_status,
            character_name: this.state.character_name,
            character_status: this.state.character_status,
            fandom_id: this.state.fandom_id,
            id: this.props.user_id
        };

        const self = this;

        axios({method: method, url: path, data: data, responseType: 'json'})
            .then(function (response) {
                let total_pages = self.state.total_pages;
                let total_count = self.state.total_count;
                if (response.data.total_pages == 0 || response.data.total_pages) {
                    total_pages = response.data.total_pages;
                }
                if (response.data.total_count == 0 || response.data.total_count) {
                    total_count = response.data.total_count;
                }
                self.setState({
                    current_page: page,
                    is_first: (page === 1),
                    is_last: (page === total_pages),
                    entries: response.data.entries,
                    total_pages: total_pages,
                    total_count: total_count
                });
            })
            .catch(function (error) {
                let error_text = '';
                if (error.response && error.response.data && (error.response.data.error || error.response.data.errors)) {
                    if (error.response.data.error) {
                        error_text = error.response.data.error;
                    } else if (error.response.data.errors) {
                        error_text = error.response.data.errors;
                    } else {
                        error_text = 'Неизвестная ошибка. Обратитесь к администратору.';
                    }
                } else {
                    error_text = 'Неизвестная ошибка. Обратитесь к администратору.';
                }
                beautiful_alert(error_text);
            });
    }


    render() {
        let table_content = this.state.entries.map((entry) =>
            <tr key={'user_entries_' + String(entry.id)}>
                {this.props.show_id && <td className="workid1">{entry.id}</td>}
                {this.props.show_category && <td className="category1">{entry.category_text}</td>}
                {this.props.show_author && <td className="name1">{entry.authors_string}</td>}
                {this.props.is_fandom && <td className="fandom_title1">{entry.title}</td>}
                {this.props.is_character && <td className="character_title1">{entry.title}</td>}
                {!this.props.is_fandom && !this.props.is_character && !this.props.no_title_link && <td className="title1"><a href={'/works/' + String(entry.work_id || entry.id)}>{entry.title}</a></td>}
                {this.props.comment_link && <td className="title1"><a href={'/works/' + String(entry.work_id) + '#comment_' + String(entry.id)}>{entry.title}</a></td>}
                {!this.props.is_fandom && !this.props.is_character && this.props.no_title_link && !this.props.comment_link && <td className="title1">{entry.title}</td>}
                {this.props.show_status && <td className="title1">{entry.status_text}</td>}
                {this.props.show_fandoms && <td className="fandom1">{entry.fandoms_string}</td>}
                {this.props.show_rating && <td className="rating1">{entry.rating}</td>}
                {this.props.show_year && <td className="year1">{entry.year_string}</td>}
                {this.props.show_nomination && <td className="nomin">
                    <div className="nomin-div">
                        <div>{entry.nomination_name}</div>
                    </div>
                </td>}
                {this.props.show_jury_notes && <td className="td-jury-notes">
                    <JuryNote icon_url={"/images/icons/note_black.png"} user_id={this.props.user_id}
                              work_id={entry.id} text={entry.note_text} name={'works_note_' + String(entry.id)}
                              onTextChange={null} show_text={false} show_support_elements={false}
                              class_name={"jury-note-works-table"} />
                </td>}
                {this.props.show_text && <td className="comment1">{entry.text}</td>}
                {this.props.show_status_text && <td className="status_text">{entry.readable_status_text}</td>}
                {this.props.show_awards && <React.Fragment>
                <td className="short_list">
                    {entry.show_shortlist_icon &&
                    <img src="/images/icons/winner_short.png" title="в шорт-листе"/>
                    }
                </td>
                <td className="winner">
                    {entry.winner_text && entry.jury_rank === 1 &&
                    <img src="/images/icons/winner_first1.png" title={entry.winner_text}/>
                    }
                    {entry.winner_text && entry.jury_rank === 2 &&
                    <img src="/images/icons/winner_second1.png" title={entry.winner_text}/>
                    }
                    {entry.winner_text && entry.jury_rank === 3 &&
                    <img src="/images/icons/winner_third1.png" title={entry.winner_text}/>
                    }
                </td>
                <td className="thanks">
                    {entry.show_thanks_icon &&
                    <img src="/images/icons/winner_fap.png" title="спасибо, подрочил"/>
                    }
                </td>
                <td className="guest">
                    {entry.guest_rank && entry.guest_rank === 1 &&
                    <img src="/images/icons/winner_guest.png" title={'победитель читательского голосования'}/>
                    }
                </td>
                 {this.props.show_comments_count && <td className="comments_counter">
                     {(String(entry.total_comments) || '') + (entry.unread_comments ? (' (' + String(entry.unread_comments) + ')') : '')}
                </td>}
                </React.Fragment>}
                {this.props.show_edit &&
                <td className="premod-edit"><a href={this.props.edit_link_base + String(entry.id)}><img src="/images/icons/pencil.png"/></a></td>}
                {this.props.show_moderation && <td className="approve">
                        {this.props.show_moderation && (!this.props.check_entry_moderation || entry.moderation) && <React.Fragment>
                            <a href="">
                            <IconClickControl title="принять" filled={false} counter={false}
                            request_path={this.props.accept_base_path + entry.id + "/accept"}
                            filled_image_src='/images/check_filled.png'
                            image_src='/images/icons/check.png' needs_fingerprint={false} callback={this.reloadWorks}
                                              is_button={false} button_text='' button_undo_text='' />
                        </a></React.Fragment>}
                        {this.props.show_approved_check && !entry.moderation && <img src={"/images/icons/check_filled.png"} title={"одобрен"}/>}
                </td>}
                {this.props.show_moderation && !this.props.show_add_fandom && !this.props.show_add_character && <td className="reject">
                    {this.props.show_moderation && !entry.moderation && <React.Fragment>
                        <a href="">
                        <IconClickControl title="отклонить" filled={false} counter={false}
                                      request_path={this.props.decline_base_path + entry.id + "/decline"}
                                      filled_image_src='/images/icons/cross.png'
                                      image_src='/images/icons/cross.png'
                                      needs_fingerprint={false} is_reject={true}  callback={this.reloadWorks}
                                          is_button={false} button_text='' button_undo_text=''/>
                    </a></React.Fragment>}
                </td>}
                {this.props.show_fap_and_shortlist &&
                <React.Fragment>
                <td className="shortlist-table-action">
                        <a href="">
                            <IconClickControl title="в шортлист" filled={entry.current_user_shorlist_param} counter={false}
                                              request_path={"/works/" + String(entry.id) + "/short"}
                                              undo_path={"/works/" + String(entry.id) + "/unshort"}
                                              filled_image_src='/images/check_filled.png'
                                              image_src='/images/check_unfilled.png' needs_fingerprint={false}
                                              is_button={false} button_text='' button_undo_text=''/>
                        </a>
                </td>
                <td className="fap-table-action">
                    <a href="">
                    <IconClickControl title="спасибо, подрочил" filled={entry.current_user_fap_param} counter={false}
                    request_path={"/works/" + String(entry.id) + "/fap"}
                    undo_path={"/works/" + String(entry.id) + "/unfap"}
                    filled_image_src='/images/fap_filled.png'
                    image_src='/images/fap_unfilled.png' needs_fingerprint={false}
                                      is_button={false} button_text='' button_undo_text=''/>
                    </a>
                </td></React.Fragment>}
                {(this.props.show_delete || this.props.show_moderation && (this.props.show_add_fandom || this.props.show_add_character)) &&
                    <td className="reject">
                    {(this.props.show_delete || entry.moderation) &&
                        <a href="">
                            <ActionWithConfirmation image_id=""
                                                    image_src="/images/icons/cross.png"
                                                    image_title='удалить' request_method='POST'
                                                    request_path={this.props.delete_request_base_path + String(entry.id)}
                                                    cancel_text='нет' yes_text='да'
                                                    confirmation_text='Вы действительно хотите удалить эту запись из базы?'
                                                    reload_after={this.props.show_add_fandom || this.props.show_add_character}
                                                    redirect_after_path={'/users/' + String(this.props.user_id) + '/operating_cabinet'} />
                        </a>}
                    </td>
                }
                {this.props.show_remove && <td className="reject"><a href="">
                    <ActionWithConfirmation image_id=""
                                            image_src="/images/icons/cross.png"
                                            image_title='удалить из избранного' request_method='POST'
                                            request_path={this.props.remove_path + String(entry.id) + this.props.remove_tail}
                                            cancel_text='нет' yes_text='да'
                                            confirmation_text='Вы действительно хотите удалить эту работу из избранного?'
                                            reload_after={true}
                                            redirect_after_path={'/users/' + String(this.props.user_id) + '#favorites'} />
                </a></td>}
            </tr>
        );
        return (
            <React.Fragment>
                <div id={this.props.div_id} className="bookmark-content user_works_content" style={{display:'none'}}>
                    {this.props.title && <span className={"bookmark-content-header"}>{this.props.title}</span>}
                    {this.props.show_fandom_search && <React.Fragment>
                        <span className="profile-nominname"><b>&bull;</b> Искать фэндом</span>
                        <div className="hidden_block hidden_block_first">
                            <form id="search-fandom">
                                <SelectInput selected={ Number(this.state.fandom_status) } list={{0: 'статус', 1: 'премодерация', 2: 'одобрен'}} name={'fandom_status'} onSelectChange={this.handleInputChange} />

                                <input type="text" className="add-fandom-input search-inputs" placeholder="название фэндома" name="fandom_name" value={this.state.fandom_name} onChange={this.handleInputChange}/>
                                <input className="bttn-dark save-fandom" type="submit" value="искать" onClick={this.handleSearchSubmit} />
                            </form>
                        </div>
                    </React.Fragment>}
                    {this.props.show_character_search && <React.Fragment>
                        <span className="profile-nominname"><b>&bull;</b> Искать персонажа</span>
                        <div className="hidden_block hidden_block_first">
                            <form id="search-character">
                                <SelectInput selected={ Number(this.state.fandom_id) } include_blank={true} blank_name="фэндом" list_array={this.props.fandoms_list} name={'fandom_id'} onSelectChange={this.handleInputChange} />
                                <SelectInput selected={ Number(this.state.character_status) } list={{0: 'статус', 1: 'премодерация', 2: 'одобрен'}} name={'character_status'} onSelectChange={this.handleInputChange} />

                                <input type="text" className="add-fandom-input search-inputs" placeholder="имя персонажа" name="character_name" value={this.state.character_name} onChange={this.handleInputChange}/>
                                <input className="bttn-dark save-fandom" type="submit" value="искать" onClick={this.handleSearchSubmit} />
                            </form>
                        </div>
                    </React.Fragment>}
                    {this.props.show_add_fandom && <React.Fragment>
                    <span className="profile-nominname"><b>&bull;</b> Добавить фэндом</span>
                        <div className="hidden_block">
                        <form id="add-fandom">

                        <input type="text" className="add-fandom-input search-inputs" placeholder="добавьте фэндом" name="add_fandom_value" value={this.state.add_fandom_value} onChange={this.handleInputChange} />

                        <input className="bttn-dark save-fandom" type="submit" value="создать" onClick={this.handleAddClick}/>
                        </form>
                        </div></React.Fragment>}
                    {this.state.total_count <= 0 &&
                        this.props.not_found_text
                    }
                    {this.props.show_add_character && <React.Fragment>
                        <span className="profile-nominname"><b>&bull;</b> Добавить персонажа</span>
                        <div className="hidden_block">
                            <form id="add-character">
                                <SelectInput selected={ Number(this.state.add_fandom_id) } include_blank={true} blank_name="фэндом" list_array={this.props.fandoms_list} name={'add_fandom_id'} onSelectChange={this.handleInputChange} />
                                <input type="text" className="add-fandom-input search-inputs" placeholder="добавьте персонажа" name="add_character_name" value={this.state.add_character_name} onChange={this.handleInputChange} />

                                <input className="bttn-dark save-fandom" type="submit" value="создать" onClick={this.handleAddClick}/>
                            </form>
                        </div></React.Fragment>}
                    {this.state.total_count > 0 &&
                    <React.Fragment>
                    <div className="overflow-div">
                        <table id="works-table" className="profile-table">
                            <tbody>
                                {table_content}
                            </tbody>
                        </table>
                    </div>
                    { this.props.new_entry_available &&
                        <div id="works-table-div">
                            <a href='/works/new'>
                                <input id="add-work" className="bttn-dark" type="submit" value="добавить работу" />
                            </a>
                        </div>
                    }
                    { this.state.total_pages !== null && this.state.total_pages > 1 && this.state.total_count > 0 &&
                    <div className="user_works_content user-works-page-counter">
                        <a id="first-p" className={this.state.is_first ? 'pagination-link-inactive' : ''} disabled={this.state.is_first ? true : false} href={''} onClick={this.toFirstPage}>&#171;</a>
                        <a id="previos-p" className={this.state.is_first ? 'pagination-link-inactive' : ''} disabled={this.state.is_first ? true : false} href={''} onClick={this.toPrevPage}>&#8249;</a>
                        <span id='current-p'>{this.state.current_page}</span>
                        <a id="next-p" className={this.state.is_last ? 'pagination-link-inactive' : ''} disabled={this.state.is_last ? true : false} href={''} onClick={this.toNextPage}>&#8250;</a>
                        <a id="last-p" className={this.state.is_last ? 'pagination-link-inactive' : ''} disabled={this.state.is_last ? true : false} href={''} onClick={this.toLastPage}>&#187;</a>
                        <span>
                        <select className="goto-page" id="goto-page" name='goto_page' onChange={this.gotoPage} value={this.state.current_page}>
                            {Array.from({length: this.state.total_pages}, (_, i) => i + 1).map((item) =>
                                <option value={item} key={'gotopage_' + String(item)}>{item}</option>
                            )}
                        </select>
                        </span>

                    </div>
                    }
                    </React.Fragment>
                    }
                </div>
            </React.Fragment>
        )
    };
}

WorksProfileTable.propTypes = {
    total_count: PropTypes.number,
    total_pages: PropTypes.number,
    entries: PropTypes.array,
    new_entry_available: PropTypes.bool,
    show_pagination: PropTypes.bool,
    user_id: PropTypes.number,
    div_id: PropTypes.string,
    not_fount_text: PropTypes.string,
    data_path: PropTypes.string,
    show_awards: PropTypes.bool,
    show_moderation: PropTypes.bool,
    show_author: PropTypes.bool,
    show_rating: PropTypes.bool,
    show_year: PropTypes.bool,
    show_category: PropTypes.bool,
    show_delete: PropTypes.bool,
    show_fandoms: PropTypes.bool,
    show_nomination: PropTypes.bool,
    show_text: PropTypes.bool,
    delete_request_base_path: PropTypes.string,
    show_status: PropTypes.bool,
    show_fandom_search: PropTypes.bool,
    show_add_fandom: PropTypes.bool,
    check_entry_moderation: PropTypes.bool,
    accept_base_path: PropTypes.string,
    decline_base_path: PropTypes.string,
    add_path: PropTypes.string,
    show_character_search: PropTypes.bool,
    show_add_character: PropTypes.bool,
    fandoms_list: PropTypes.array,
    no_title_link: PropTypes.bool,
    comment_link: PropTypes.bool,
    show_edit: PropTypes.bool,
    show_approved_check: PropTypes.bool,
    edit_link_base: PropTypes.string,
    not_found_text: PropTypes.string,
    is_fandom: PropTypes.bool,
    is_character: PropTypes.bool,
    show_status_text: PropTypes.bool,
    show_comments_count: PropTypes.bool,
    show_id: PropTypes.bool,
    show_remove: PropTypes.bool,
    remove_path: PropTypes.string,
    remove_tail: PropTypes.string,
    show_jury_notes: PropTypes.bool,
    title: PropTypes.string
};

export default WorksProfileTable;