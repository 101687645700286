import React from "react"
import PropTypes from "prop-types";

const Functions = require('./functions.js');
const axios = require('axios').default;
import {beautiful_alert} from "../plain_js/layout.js";
import {close_beautiful_alert} from "../plain_js/layout.js";

import {beautiful_alert_confirmation} from "../plain_js/layout.js";

class ActionWithConfirmation extends React.Component {
    constructor(props) {
        super(props);
        this.showQuestion = this.showQuestion.bind(this);
        this.handleClickOk = this.handleClickOk.bind(this);
    }

    showQuestion(event) {
        event.preventDefault();
        beautiful_alert_confirmation(this.props.confirmation_text, this.props.yes_text, this.props.cancel_text, this.handleClickOk);
    }

    handleClickOk(event) {
        event.preventDefault();
        let data = {
            authenticity_token: Functions.getMetaContent("csrf-token")
        };
        const self = this;

        axios({method: this.props.request_method, url: this.props.request_path, data: data, responseType: 'json'})
            .then(function (response) {
                if (self.props.reload_after) {
                    window.location.reload();
                    return;
                }
                if (self.props.redirect_after_path) {
                    window.location.href = self.props.redirect_after_path;
                }
                if (self.props.callback) {
                    close_beautiful_alert();
                    self.props.callback();
                }
            })
            .catch(function (error) {
                let error_text = '';
                if (error.response && error.response.data && (error.response.data.error || error.response.data.errors)) {
                    if (error.response.data.error) {
                        error_text = error.response.data.error;
                    } else if (error.response.data.errors) {
                        error_text = error.response.data.errors;
                    } else {
                        error_text = 'Неизвестная ошибка. Обратитесь к администратору.';
                    }
                } else {
                    error_text = 'Неизвестная ошибка. Обратитесь к администратору.';
                }
                beautiful_alert(error_text);
            });
    }

    render() {
        if (this.props.text_instead_of_button) {
            return(<React.Fragment>
                <a href=''  onClick={this.showQuestion}>
                    {this.props.text}
                </a>
            </React.Fragment>);
        } else {
            return(<React.Fragment>
                <img id={this.props.image_id} src={this.props.image_src} title={this.props.image_title} onClick={this.showQuestion} />
            </React.Fragment>);
        }
    }
}

ActionWithConfirmation.propTypes = {
    image_src: PropTypes.string,
    image_id: PropTypes.string,
    image_title: PropTypes.string,
    confirmation_text: PropTypes.string,
    request_path: PropTypes.string,
    request_method: PropTypes.string,
    redirect_after_path: PropTypes.string,
    yes_text: PropTypes.string,
    cancel_text: PropTypes.string,
    text_instead_of_button: PropTypes.bool,
    reload_after: PropTypes.bool,
    text: PropTypes.string,
    callback: PropTypes.func
};

export default ActionWithConfirmation