import React from "react"
import PropTypes from "prop-types";

const Functions = require('./functions.js');
const axios = require('axios').default;
import {beautiful_alert} from "../plain_js/layout.js";
import SelectInput from "./SelectInput";

class ContestResults extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type_cd: this.props.type_cd || this.props.types[0][1],
            contest_type_cd: this.props.contest_type_cd || this.props.contest_types[0][1],
            year: this.props.year || this.props.years[this.props.years.length - 1][1],
            text: ''
        };
        this.onChange = this.onChange.bind(this);
        this.makeRequest = this.makeRequest.bind(this);
        this.processMores = this.processMores.bind(this);

        this.resultsRef = React.createRef();

        this.makeRequest();
    }

    processMores() {
        const more_labels = this.resultsRef.current.getElementsByClassName("more_label");
        const openMore = function() {
            this.style.display = 'none';
            this.nextSibling.style.display = 'block';
        };
        for (let i = 0; i < more_labels.length; i++) {
            more_labels[i].addEventListener('click', openMore, false);
        }

        const mores = this.resultsRef.current.getElementsByClassName("more_container");
        const closeMore = function() {
            this.style.display = 'none';
            this.previousSibling.style.display = 'block';
        };
        for (let i = 0; i < mores.length; i++) {
            mores[i].addEventListener('dblclick', closeMore, false);
        }
    }

    onChange(e) {
        e.preventDefault();
        this.setState(
            {[e.target.name]: e.target.value}, this.makeRequest
        );
    }

    makeRequest() {
        let path = '/results_by_params';
        let method = 'POST';

        let data = {
            authenticity_token: Functions.getMetaContent("csrf-token"),
            year: this.state.year,
            type_cd: this.state.type_cd,
            contest_type_cd: this.state.contest_type_cd
        };

        const self = this;

        axios({method: method, url: path, data: data, responseType: 'json'})
            .then(function (response) {
                window.history.pushState({}, null,
                    window.location.href.split('?')[0] + '?type_cd=' + String(self.state.type_cd) +
                    '&year=' + String(self.state.year));
                self.setState({text: response.data.text}, self.processMores)
            })
            .catch(function (error) {
                console.log(error);
                console.log(error.response);
                let error_text = '';
                if (error.response && error.response.data && (error.response.data.error || error.response.data.errors)) {
                    if (error.response.data.error) {
                        error_text = error.response.data.error;
                    } else if (error.response.data.errors) {
                        error_text = error.response.data.errors;
                    } else {
                        error_text = 'Неизвестная ошибка. Обратитесь к администратору.';
                    }
                } else {
                    error_text = 'Неизвестная ошибка. Обратитесь к администратору.';
                }
                beautiful_alert(error_text);
            });
    }

    render () {
        return (
            <React.Fragment>
                <div className="lines">

                    <SelectInput class_name="litle-column" selected={ Number(this.state.year) }
                                 list_array={this.props.years}
                                 name='year' include_blank={false} onSelectChange={this.onChange} />

                    <SelectInput class_name="litle-column" selected={ Number(this.state.type_cd) }
                                 list_array={this.props.types}
                                 name='type_cd' include_blank={false} onSelectChange={this.onChange} />

                </div>

                <div id="contest_results_div" ref={this.resultsRef} dangerouslySetInnerHTML={{__html: this.state.text}}></div>
            </React.Fragment>
        )
    }
}

ContestResults.propTypes = {
    types: PropTypes.array,
    contest_types: PropTypes.array,
    years: PropTypes.array,
    type: PropTypes.number,
    contest_type: PropTypes.number,
    year: PropTypes.number
};

export default ContestResults