import React from "react"
import PropTypes from "prop-types";
import SelectInput from "./SelectInput";

class ComplexSelectInput extends React.Component {
    constructor(props) {
        super(props);
        this.triggerEvent = this.triggerEvent.bind(this);
        this.handleSelectInputChange = this.handleSelectInputChange.bind(this);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);

        this.state = {
            selected_array: this.props.selected_array,
            list: this.getList(),
            list_array: this.props.list_array ? this.getListArray() : null,
            dictionary: this.getDictionary(),
            selected_string: this.selectedStringFromArray(this.props.selected_array),
            selected_items_view: this.selectedItemsViewFromArray(this.props.selected_array)
        };

        this.selectedRef = React.createRef();
    }

    selectedItemsViewFromArray(selected_array) {
        let full_list;
        if (this.state) {
            full_list = this.state.dictionary;
        } else {
            full_list = this.getDictionary();
        }
        if (selected_array && selected_array.length > 0) {
            let prev_id = null;
            let result = [];
            let tmp_string = '';
            let tmp_indices = [];
            selected_array.forEach((item, index) => {
                if (tmp_string === '') {
                    tmp_string = full_list[item];
                    tmp_indices = [index];
                } else {
                    if (prev_id < 1 || item < 1) {
                        tmp_string = tmp_string + ' ' + full_list[item];
                        tmp_indices.push(index);
                    } else {
                        result.push({text: tmp_string, indices: tmp_indices});
                        tmp_string = full_list[item];
                        tmp_indices = [index];
                    }
                }
                prev_id = item;
            });

            if (tmp_string !== '') {
                result.push({text: tmp_string, indices: tmp_indices});
            }
            return result;
        } else {
            return [];
        }
    }

    selectedStringFromArray(selected_array) {
        let full_list;
        if (this.state) {
            full_list = this.state.dictionary;
        } else {
            full_list = this.getDictionary();
        }
        if (selected_array && selected_array.length > 0) {
            let prev_id = null;
            return selected_array.reduce((previous, current) => {
                let result = null;
                if (previous === '') {
                    result = full_list[current];
                } else {
                    if (prev_id < 1 || current < 1) {
                        result = previous + ' ' + full_list[current];
                    } else {
                        result = previous + '; ' + full_list[current];
                    }
                }
                prev_id = current;
                return result;
            }, '');
        } else {
            return '';
        }
    }

    triggerEvent() {
        if (this.state.selected_array.length === 0) {
            this.hideSelectedAndDel();
        } else {
            this.showSelectedAndDel();
        }
        const event = new CustomEvent("input", { bubbles: true, 'detail': {name: this.props.name, value: this.state.selected_array} });
        this.props.onSelectChange(event);
    }

    componentDidUpdate(prevProps) {
        if (this.props.list !== prevProps.list || this.props.list_array != prevProps.list_array) {
            if (this.props.list_array) {
                this.setState({'list_array': this.getListArray(), 'dictionary': this.getDictionary()});
            } else {
                this.setState({'list': this.getList(), 'dictionary': this.getDictionary()});

            }
        }
    }

    getListArray() {
        if (this.props.list_array) {
            let list = [['', 0]];
            if (this.props.type === 'character') {
                list.push(['/', 0.1], ['|', 0.2]);
            }
            list = list.concat(this.props.list_array);
            return list;
        } else {
            return [];
        }
    }

    getList() {
        if (this.props.list) {
            let list = this.props.list;
            list[0] = '';
            if (this.props.type === 'character') {
                list[0.1] = '/';
                list[0.2] = '|';
            }
            return list;
        } else {
            return {}
        }
    }

    getDictionary() {
        let dictionary = this.props.dictionary;
        if (dictionary) {
        } else {
            dictionary = {};
            this.props.dictionary_array.forEach((item) => {
                dictionary[item[1]] = item[0];
            });
        }

        let list = dictionary;

        if (list) {
            list[0] = '';
            if (this.props.type === 'character') {
                list[0.1] = '/';
                list[0.2] = '|';
            }
            return list;
        } else {
            return {}
        }
    }

    handleSelectInputChange(e) {
        let value;
        if (e.target) {
            value = Number(e.target.value);
        } else {
            value = Number(e.detail.value);
        }
        if (value === 0 ||
            value >= 1 &&
            (this.props.type !== 'character' && this.state.selected_array.includes(value) ))
        {
            return;
        }
        if (this.props.type === 'character' && (this.state.selected_array.length < 1 || this.state.selected_array[this.state.selected_array.length - 1] < 1) && value < 1) {
            return;
        }
        let new_selected_array = this.state.selected_array;
        new_selected_array.push(value);
        this.setState({
            selected_array: new_selected_array,
            selected_string: this.selectedStringFromArray(new_selected_array),
            selected_items_view: this.selectedItemsViewFromArray(new_selected_array)
        }, this.triggerEvent);
    }

    handleDeleteClick(e) {
        if (this.props.can_delete_from_middle) {
            let new_selected_array = [];
            let items_view_index = e.target.dataset.index;
            let indices_to_remove = this.state.selected_items_view[items_view_index].indices;
            this.state.selected_array.forEach((item, index) => {
                if (!indices_to_remove.includes(index)) {
                    new_selected_array.push(item);
                }
            });
            this.setState({
                selected_array: new_selected_array,
                selected_items_view: this.selectedItemsViewFromArray(new_selected_array)
            }, this.triggerEvent);
        } else {
            let new_selected_array = this.state.selected_array;
            new_selected_array.pop();
            this.setState({
                selected_array: new_selected_array,
                selected_string: this.selectedStringFromArray(new_selected_array)
            }, this.triggerEvent);
        }
    }

    showSelectedAndDel() {
        this.selectedRef.current.style.display = 'block';
    }

    hideSelectedAndDel() {
        this.selectedRef.current.style.display = 'none';
    }

    render () {
        return(<React.Fragment>
            <div>
                <SelectInput customStyles={this.props.customStyles} customFocusedStyles={this.props.customFocusedStyles} list={this.props.list ? this.state.list : null} list_array={this.props.list_array ? this.state.list_array : null} name={'fandoms_select'} selected={0} onSelectChange={this.handleSelectInputChange} with_suggestions={this.props.with_suggestions} />
                <div ref={this.selectedRef}
                     style={this.props.selected_array && this.props.selected_array.length > 0 ? {display:'block'} : {display:'none'}}>
                    {!this.props.can_delete_from_middle && <React.Fragment>
                        <div className="readonly">{this.state.selected_string}</div>
                        <span className="del-last" onClick={this.handleDeleteClick}>&times;</span>
                    </React.Fragment>}
                    {this.props.can_delete_from_middle &&
                        this.state.selected_items_view.map((item, index) => <React.Fragment key={"seleted_items_view_" + String(index)}>
                            <div className="readonly">{item.text}</div>
                            <span className="del-last" data-index={index} onClick={this.handleDeleteClick}>&times;</span>
                        </React.Fragment>)
                    }
                </div>
            </div>
        </React.Fragment>);
    }
}

ComplexSelectInput.propTypes = {
    list: PropTypes.object,
    list_array: PropTypes.array,
    dictionary: PropTypes.object,
    dictionary_array: PropTypes.array,
    onSelectChange: PropTypes.func,
    selected_array: PropTypes.array,
    name: PropTypes.string,
    type: PropTypes.string,
    with_suggestions: PropTypes.bool,
    can_delete_from_middle: PropTypes.bool,
    customStyles: PropTypes.array,
    customFocusedStyles: PropTypes.object
};

export default ComplexSelectInput