import React from "react"
import PropTypes from "prop-types";

const Functions = require('./functions.js');
const axios = require('axios').default;
import {beautiful_alert} from "../plain_js/layout.js";
import TextInputWithButtons from "./TextInputWithButtons";

class EditReview extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            html: this.props.html,
            title: this.props.title,
            links: this.props.links,
            text_input_key: 0,
            time: this.props.time
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();
        let method = 'POST';
        let path = '/reviews/' + String(this.props.id);
        const self = this;
        let data = {
            authenticity_token: Functions.getMetaContent("csrf-token"),
            title: this.state.title,
            html: this.state.html,
            links: this.state.links,
            user_id: this.props.user_id,
            time: this.state.time
        };

        axios({method: method, url: path, data: data, responseType: 'json'})
            .then(function (response) {
                window.location.href = self.props.redirect_after;
            })
            .catch(function (error) {
                let error_text = '';
                if (error.response && error.response.data && (error.response.data.error || error.response.data.errors)) {
                    if (error.response.data.error) {
                        error_text = error.response.data.error;
                    } else if (error.response.data.errors) {
                        error_text = error.response.data.errors;
                    } else {
                        error_text = 'Неизвестная ошибка. Обратитесь к администратору.';
                    }
                } else {
                    error_text = 'Неизвестная ошибка. Обратитесь к администратору.';
                }
                beautiful_alert(error_text);
            });
    }

    handleInputChange(e) {
        const target = e.target;
        let name, value;
        if (target) {
            name = target.name;
            value = target.value;
        } else {
            value = e.detail.value;
            name = e.detail.name;
        }

        this.setState({
            [name]: value
        });
    }

    render() {
        return (
            <React.Fragment>
                <div id="all-news-block">
                    <h2 id="static_page_title">{"Редактирование отзыва"}</h2>
                    <div id="write-review">
                        <p>{this.props.header}</p>

                        <div className="edit-comment">
                            <textarea id="add-review-title" name="title" value={this.state.title} onChange={this.handleInputChange} placeholder="заголовок"/>

                            <form id="add-review-form" action="POST">
                                <TextInputWithButtons key={this.state.text_input_key} name="html" text={this.state.html} onTextChange={this.handleInputChange} placeholder={this.props.placeholder}/>

                                <p>Добавьте ссылки на работы, которые упоминаются в тексте (по одной ссылке в строке, только на работы на сайте Nebooker).</p>
                                <textarea id="add-links-area" name="links" value={this.state.links} onChange={this.handleInputChange} />

                                {this.props.is_admin && <React.Fragment><p>Установите время публикации отзыва (по московскому времени), в формате "2020-06-30 15:00"</p>
                                    <textarea id="add-time-area" placeholder="укажите время публикации" name="time" value={this.state.time} onChange={this.handleInputChange} /></React.Fragment>}

                                <span id="add-review-go" className="bttn-dark bttn" onClick={this.handleSubmit}>{ this.props.is_admin ? 'сохранить' : 'отправить' }</span>
                            </form>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

EditReview.propTypes = {
    html: PropTypes.string,
    title: PropTypes.string,
    links: PropTypes.string,
    id: PropTypes.number,
    user_id: PropTypes.number,
    redirect_after: PropTypes.string,
    time: PropTypes.string,
    is_admin: PropTypes.bool
}

export default EditReview