import React from "react"
import PropTypes from "prop-types";

const Functions = require('./functions.js');
const axios = require('axios').default;
import {pstfgrpnt} from "../plain_js/detector.js";
import {beautiful_alert} from "../plain_js/layout.js";

class IconClickControl extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filled: this.props.filled,
            active_count: this.props.active_count,
            actual_image_src: this.props.filled ? this.props.filled_image_src : this.props.image_src,
            reject_reason: ''
        };
        this.fillIcon = this.fillIcon.bind(this);
        this.unfillIcon = this.unfillIcon.bind(this);
        this.setCounter = this.setCounter.bind(this);
        this.onClick = this.onClick.bind(this);
        this.makeRequest = this.makeRequest.bind(this);
        this.askRejectReason = this.askRejectReason.bind(this);
        this.getRejectReason = this.getRejectReason.bind(this);
        this.closeRejectReason = this.closeRejectReason.bind(this);
        this.imgRef = React.createRef();
        this.counterRef = React.createRef();
    }

    fillIcon() {
        if (this.props.is_button) {
            return;
        }
        this.imgRef.current.src = this.props.filled_image_src;
    }

    unfillIcon() {
        if (this.props.is_button) {
            return;
        }
        this.imgRef.current.src = this.props.image_src;
    }

    setCounter(value) {
        this.counterRef.current.innerHTML = value;
        if (value > 0) {
            this.counterRef.current.style.display = 'block';
        } else {
            this.counterRef.current.style.display = 'none';
        }
    }

    onClick(e) {
        e.preventDefault();
        if (this.props.is_reject) {
            this.askRejectReason();
        } else {
            this.makeRequest();
        }
    }

    askRejectReason() {
        if (this.state.filled) {
            if (!this.props.undo_path) {
                beautiful_alert('Действие нельзя отменить');
                return;
            }
        }
        document.getElementById("alert-modal").classList.add("show-modal");
        document.getElementById("warning").innerHTML = "Укажите причину отказа" + "<textarea id='reject_reason'></textarea>";
        let cancel_button = document.getElementById("out");
        cancel_button.style.display = "inline-block";
        cancel_button.addEventListener('click', this.closeRejectReason, false);
        let ok_button = document.getElementById("in");
        ok_button.innerHTML = "ok";
        ok_button.addEventListener('click', this.getRejectReason, false);
    }

    closeRejectReason(e) {
        e.preventDefault();
        document.getElementById("alert-modal").classList.remove("show-modal");
        const cancel_button = document.getElementById("out");
        cancel_button.removeEventListener('click', this.closeRejectReason);
        const ok_button = document.getElementById("in");
        ok_button.removeEventListener('click', this.getRejectReason);
    }

    getRejectReason(e) {
        e.preventDefault();
        if (this.state.filled) {
            if (!this.props.undo_path) {
                beautiful_alert('Действие нельзя отменить.');
                return;
            }
        }
        const reason = document.getElementById("reject_reason");
        if (reason && reason.value) {
            document.getElementById("alert-modal").classList.remove("show-modal");
            const cancel_button = document.getElementById("out");
            cancel_button.removeEventListener('click', this.closeRejectReason);
            const ok_button = document.getElementById("in");
            ok_button.removeEventListener('click', this.getRejectReason);
            this.setState({reject_reason: reason.value}, this.makeRequest);
        } else {
            beautiful_alert('Нужно указать причину отказа');
        }
    }

    makeRequest() {
        let path = '';
        let method = 'POST';
        if (this.state.filled) {
            if (this.props.undo_path) {
                path = this.props.undo_path;
            } else {
                beautiful_alert('Действие нельзя отменить.');
                return;
            }
        } else {
            path = this.props.request_path;
        }
        let data = {
            authenticity_token: Functions.getMetaContent("csrf-token")
        };

        if (this.props.needs_fingerprint) {
            data['fp'] = pstfgrpnt();
        }
        if (this.props.is_reject) {
            data['reject_reason'] = this.state.reject_reason;
        }

        const self = this;

        axios({method: method, url: path, data: data, responseType: 'json'})
            .then(function (response) {
                console.log('here!33');
                if (self.state.filled) {
                    self.setState({filled: false});
                    self.unfillIcon();
                } else {
                    self.setState({filled: true});
                    self.fillIcon();
                    if (response.data && response.data.status_text) {
                        let work_status_elem = document.getElementById("work-status")
                        if (work_status_elem) {
                            work_status_elem.innerHTML = response.data.status_text;
                        }
                    }
                }
                if (self.props.counter) {
                    self.setState({active_count: response.data.active_count}, () => {
                        self.setCounter(response.data.active_count);
                    });
                }
                if (self.props.callback) {
                    self.props.callback()
                }
            })
            .catch(function (error) {
                console.log(error);
                console.log(error.response);
                let error_text = '';
                if (error.response && error.response.data && (error.response.data.error || error.response.data.errors)) {
                    if (error.response.data.error) {
                        error_text = error.response.data.error;
                    } else if (error.response.data.errors) {
                        error_text = error.response.data.errors;
                    } else {
                        error_text = 'Неизвестная ошибка. Обратитесь к администратору.';
                    }
                } else {
                    error_text = 'Неизвестная ошибка. Обратитесь к администратору.';
                }
                beautiful_alert(error_text);
            });
    }

    render () {
        return (
            <React.Fragment>
                {!this.props.is_button && <React.Fragment><img ref={this.imgRef} title={this.props.title} src={this.state.actual_image_src} onClick={this.onClick}/>
                    <span ref={this.counterRef} className={this.props.span_class} style={this.state.active_count > 0 ? {display: 'block'} : {display: 'none'}}>{this.state.active_count}</span></React.Fragment>}
                {this.props.is_button && <a href="#" id="vote-bttn" className="bttn-dark bttn"  onClick={this.onClick}>{this.state.filled ? this.props.button_undo_text : this.props.button_text}</a>}
            </React.Fragment>
        )
    }


}

IconClickControl.propTypes = {
    filled: PropTypes.bool,
    image_src: PropTypes.string,
    filled_image_src: PropTypes.string,
    request_path: PropTypes.string,
    undo_path: PropTypes.string,
    counter: PropTypes.bool,
    needs_fingerprint: PropTypes.bool,
    active_count: PropTypes.number,
    span_class: PropTypes.string,
    title: PropTypes.string,
    is_reject: PropTypes.bool,
    is_button: PropTypes.bool,
    button_text: PropTypes.string,
    button_undo_text: PropTypes.string
};

export default IconClickControl