import React from "react"
import PropTypes from "prop-types";
import TextInputWithButtons from "./NewsForm";

const Functions = require('./functions.js');
const axios = require('axios').default;

class PasswordForm extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.closeError = this.closeError.bind(this);
        this.showError = this.showError.bind(this);
        this.state = {
            password: '',
            password_confirmation: ''
        };
        this.errorsRef = React.createRef();
        this.errorsTextRef = React.createRef();
    }

    handleChange(e) {
        const target = e.target;
        let name, value;
        if (target) {
            name = target.name;
            value = target.value;
        } else {
            value = e.detail.value;
            name = e.detail.name;
        }
        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        let path = '/users/password';
        let method = 'PATCH';

        let data = {
            authenticity_token: Functions.getMetaContent("csrf-token"),
            reset_password_token: this.props.token,
            password: this.state.password,
            password_confirmation: this.state.password_confirmation
        };

        const self = this;

        axios({method: method, url: path, data: data, responseType: 'json'})
            .then(function (response) {
                window.location.href = '/info';
            })
            .catch(function (error) {
                let error_text = '';
                if (error.response && error.response.data && (error.response.data.error || error.response.data.errors)) {
                    if (error.response.data.error) {
                        error_text = error.response.data.error;
                    } else if (error.response.data.errors) {
                        error_text = error.response.data.errors;
                    } else {
                        error_text = 'Неизвестная ошибка. Обратитесь к администратору.';
                    }
                } else {
                    error_text = 'Неизвестная ошибка. Обратитесь к администратору.';
                }
                self.showError(error_text);
            });
    }

    showError(text) {
        this.errorsRef.current.style.display = 'block';
        this.errorsTextRef.current.innerHTML = text;
    }

    closeError() {
        this.errorsRef.current.style.display = 'none';
    }

    render() {
        const title = 'Смена пароля';
        return (
            <React.Fragment>
                <div id="all-news-block">
                    <h2 id="static_page_title">{title}</h2>
                    <div id="edit-news-block-div">
                        <form id="edit-news" action="POST">

                            <input id="edit-password" type="password" name="password" autocomplete="off" placeholder='pass' value={this.state.password || ''} onChange={this.handleChange} />
                            <input id="edit-password-confirmation" type="password" autocomplete="off" name="password_confirmation" placeholder='repeat pass' value={this.state.password_confirmation || ''} onChange={this.handleChange} />

                            <div className="news-error-sign" ref={this.errorsRef}>
                                <span id="close-sign3" onClick={this.closeError}>&times;</span>
                                <div id="news-form-error" ref={this.errorsTextRef}></div>
                            </div>

                            <span id="edit-news-go" className="bttn-dark bttn" onClick={this.handleSubmit}>подтвердить</span>
                        </form>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

PasswordForm.propTypes = {
    token: PropTypes.string
};

export default PasswordForm