import React from "react"
import PropTypes from "prop-types";
import SelectInput from "./SelectInput";

class CombinedSelectInput extends React.Component {
    constructor(props) {
        super(props);
        this.triggerEvent = this.triggerEvent.bind(this);
        this.handleSelectInputChange = this.handleSelectInputChange.bind(this);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);

        this.state = {
            selected_value: this.props.selected,
            list: this.getList(),
            list_array: this.props.list_array ? this.getListArray() : null,
            dictionary: this.getDictionary(),
            selected_string: this.selectedStringFromSelected(this.props.selected)
        };

        this.selectedRef = React.createRef();
    }

    selectedStringFromSelected(selected) {
        let full_list;
        if (this.state) {
            full_list = this.state.dictionary;
        } else {
            full_list = this.getDictionary();
        }
        if (selected) {
            return full_list[selected];
        } else {
            return '';
        }
    }

    triggerEvent() {
        if (this.state.selected_value === null || this.state.selected_value === 0) {
            this.hideSelectedAndDel();
        } else {
            this.showSelectedAndDel();
        }
        const event = new CustomEvent("input", { bubbles: true, 'detail': {name: this.props.name, value: this.state.selected_value} });
        this.props.onSelectChange(event);
    }

    getListArray() {
        if (this.props.list_array) {
            let list = [['', 0]];
            list = list.concat(this.props.list_array);
            return list;
        } else {
            return [];
        }
    }

    getList() {
        if (this.props.list) {
            let list = this.props.list;
            list[0] = '';
            return list;
        } else {
            return {}
        }
    }

    getDictionary() {
        let dictionary = this.props.dictionary;
        if (dictionary) {
        } else {
            dictionary = {};
            this.props.dictionary_array.forEach((item) => {
                dictionary[item[1]] = item[0];
            });
        }

        let list = dictionary;

        if (list) {
            list[0] = '';
            return list;
        } else {
            return {}
        }
    }

    handleSelectInputChange(e) {
        let value;
        if (e.target) {
            value = Number(e.target.value);
        } else {
            value = Number(e.detail.value);
        }
        let new_selected = value;
        this.setState({
            selected_value: new_selected,
            selected_string: this.selectedStringFromSelected(new_selected),
        }, this.triggerEvent);
    }

    handleDeleteClick(e) {
        this.setState({
            selected_value: null,
            selected_string: this.selectedStringFromSelected(null)
        }, this.triggerEvent);

    }

    showSelectedAndDel() {
        this.selectedRef.current.style.display = 'block';
    }

    hideSelectedAndDel() {
        this.selectedRef.current.style.display = 'none';
    }

    render () {
        return(<React.Fragment>
            <div className={this.props.class_name}>
                <SelectInput customStyles={this.props.customStyles} customFocusedStyles={this.props.customFocusedStyles}
                             list={this.props.list ? this.state.list : null}
                             list_array={this.props.list_array ? this.state.list_array : null} name={'combined_select'}
                             selected={undefined} onSelectChange={this.handleSelectInputChange} with_suggestions={true}
                             blank_name={this.props.blank_name}
                                include_blank={this.props.include_blank}/>
                <div ref={this.selectedRef}
                     style={this.props.selected && this.props.selected > 0 ? {display:'block'} : {display:'none'}}>
                    <React.Fragment>
                        <div className="readonly">{this.state.selected_string}</div>
                        <span className="del-last" onClick={this.handleDeleteClick}>&times;</span>
                    </React.Fragment>
                </div>
            </div>
        </React.Fragment>);
    }


}

CombinedSelectInput.propTypes = {
    list: PropTypes.object,
    list_array: PropTypes.array,
    dictionary: PropTypes.object,
    dictionary_array: PropTypes.array,
    onSelectChange: PropTypes.func,
    selected: PropTypes.number,
    name: PropTypes.string,
    class_name: PropTypes.string,
    include_blank: PropTypes.bool,
    blank_name: PropTypes.string,
    customStyles: PropTypes.array,
    customFocusedStyles: PropTypes.object
};

export default CombinedSelectInput