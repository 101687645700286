import React from "react"
import PropTypes from "prop-types";
import TextInputWithButtons from "./TextInputWithButtons";

const Functions = require('./functions.js');
const axios = require('axios').default;

class ThreadCommentForm extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.closeError = this.closeError.bind(this);
        this.showError = this.showError.bind(this);
        this.state = {
            html: this.props.text
        };
        this.errorsRef = React.createRef();
        this.errorsTextRef = React.createRef();
    }

    handleChange(e) {
        const target = e.target;
        let name, value;
        if (target) {
            name = target.name;
            value = target.value;
        } else {
            value = e.detail.value;
            name = e.detail.name;
        }
        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        const path = this.props.data_path;
        const method = 'POST';

        let data = {
            authenticity_token: Functions.getMetaContent("csrf-token"),
            text: this.state.html
        };

        const self = this;

        axios({method: method, url: path, data: data, responseType: 'json'})
            .then(function (response) {
                window.location.href = self.props.redirect_after_path;
            })
            .catch(function (error) {
                let error_text = '';
                if (error.response && error.response.data && (error.response.data.error || error.response.data.errors)) {
                    if (error.response.data.error) {
                        error_text = error.response.data.error;
                    } else if (error.response.data.errors) {
                        error_text = error.response.data.errors;
                    } else {
                        error_text = 'Неизвестная ошибка. Обратитесь к администратору.';
                    }
                } else {
                    error_text = 'Неизвестная ошибка. Обратитесь к администратору.';
                }
                self.showError(error_text);
            });
    }

    showError(text) {
        this.errorsRef.current.style.display = 'block';
        this.errorsTextRef.current.innerHTML = text;
    }

    closeError() {
        this.errorsRef.current.style.display = 'none';
    }

    render() {
        return (
            <React.Fragment>
                <TextInputWithButtons inner_id="post-comment-form" name="html" text={this.state.html} onTextChange={this.handleChange}  onCtrlEnter={this.handleSubmit} />
                <div className="news-error-sign" ref={this.errorsRef}>
                    <span id="close-sign3" onClick={this.closeError}>&times;</span>
                    <div id="news-form-error" ref={this.errorsTextRef}></div>
                </div>
                <span id="thread-comment-go" className="bttn-dark bttn" onClick={this.handleSubmit}>отправить</span>
            </React.Fragment>
        );
    }


}

ThreadCommentForm.propTypes = {
    text: PropTypes.string,
    id: PropTypes.number,
    thread_id: PropTypes.number,
    is_private: PropTypes.bool,
    data_path: PropTypes.string,
    redirect_after_path: PropTypes.string
};

export default ThreadCommentForm