import React from "react"
import PropTypes from "prop-types";

const Functions = require('./functions.js');
const axios = require('axios').default;
import {beautiful_alert} from "../plain_js/layout.js";
import TextInputWithButtons from "./TextInputWithButtons";
import IconClickControl from "./IconClickControl";
import ActionWithConfirmation from "./ActionWithConfirmation";

class ReviewsTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            html: '',
            title: '',
            links: '',
            entries: this.props.entries,
            current_page: 1,
            is_first: true,
            is_last: false,
            total_count: this.props.total_count,
            total_pages: this.props.total_pages,
            text_input_key: 0
        };
        this.toFirstPage = this.toFirstPage.bind(this);
        this.toPrevPage = this.toPrevPage.bind(this);
        this.toNextPage = this.toNextPage.bind(this);
        this.toLastPage = this.toLastPage.bind(this);
        this.getData = this.getData.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.reloadEntries = this.reloadEntries.bind(this);
        this.reloadEntriesWithCurrentPage = this.reloadEntriesWithCurrentPage.bind(this);
        this.gotoPage = this.gotoPage.bind(this);
    }

    gotoPage(event) {
        this.getData(Number(event.target.value));
    }

    handleSubmit(e) {
        e.preventDefault();
        let method = 'POST';
        let path = '/reviews/create';
        const self = this;
        let data = {
            authenticity_token: Functions.getMetaContent("csrf-token"),
            title: this.state.title,
            html: this.state.html,
            links: this.state.links,
            user_id: this.props.user_id
        };

        axios({method: method, url: path, data: data, responseType: 'json'})
            .then(function (response) {
                self.setState({current_page: 1, title: '', html: '', links: '', text_input_key: self.state.text_input_key + 1}, self.getData(1));
                beautiful_alert('Успешно отправлено на модерацию');
            })
            .catch(function (error) {
                let error_text = '';
                if (error.response && error.response.data && (error.response.data.error || error.response.data.errors)) {
                    if (error.response.data.error) {
                        error_text = error.response.data.error;
                    } else if (error.response.data.errors) {
                        error_text = error.response.data.errors;
                    } else {
                        error_text = 'Неизвестная ошибка. Обратитесь к администратору.';
                    }
                } else {
                    error_text = 'Неизвестная ошибка. Обратитесь к администратору.';
                }
                beautiful_alert(error_text);
            });
    }

    handleInputChange(e) {
        const target = e.target;
        let name, value;
        if (target) {
            name = target.name;
            value = target.value;
        } else {
            value = e.detail.value;
            name = e.detail.name;
        }

        this.setState({
            [name]: value
        });
    }

    toFirstPage(e) {
        e.preventDefault();
        if (e.target.className == 'pagination-link-inactive') {
            return;
        }
        this.getData(1);
    }

    toPrevPage(e) {
        e.preventDefault();
        if (e.target.className == 'pagination-link-inactive') {
            return;
        }
        this.getData(this.state.current_page - 1);
    }

    toNextPage(e) {
        e.preventDefault();
        if (e.target.className == 'pagination-link-inactive') {
            return;
        }
        this.getData(this.state.current_page + 1);
    }

    toLastPage(e) {
        e.preventDefault();
        if (e.target.className == 'pagination-link-inactive') {
            return;
        }
        this.getData(this.state.total_pages);
    }

    reloadEntries() {
        this.getData(1);
    }

    reloadEntriesWithCurrentPage() {
        this.getData(this.state.current_page);
    }

    getData(page) {
        let path = '';
        if (this.props.is_admin) {
            path = '/users/moderator_reviews_data';
        } else {
            path = '/users/reviews_data';
        }
        let method = 'POST';

        let data = {
            authenticity_token: Functions.getMetaContent("csrf-token"),
            page: page,
            id: this.props.user_id
        };

        const self = this;

        axios({method: method, url: path, data: data, responseType: 'json'})
            .then(function (response) {
                console.log('here!');
                console.log(response.data);
                let total_pages = self.state.total_pages;
                let total_count = self.state.total_count;
                if (response.data.total_pages == 0 || response.data.total_pages) {
                    total_pages = response.data.total_pages;
                }
                if (response.data.total_count == 0 || response.data.total_count) {
                    total_count = response.data.total_count;
                }
                self.setState({
                    current_page: page,
                    is_first: (page === 1),
                    is_last: (page === total_pages),
                    entries: response.data.entries,
                    total_pages: total_pages,
                    total_count: total_count
                });
            })
            .catch(function (error) {
                console.log('here!1');
                let error_text = '';
                if (error.response && error.response.data && (error.response.data.error || error.response.data.errors)) {
                    if (error.response.data.error) {
                        error_text = error.response.data.error;
                    } else if (error.response.data.errors) {
                        error_text = error.response.data.errors;
                    } else {
                        error_text = 'Неизвестная ошибка. Обратитесь к администратору.';
                    }
                } else {
                    error_text = 'Неизвестная ошибка. Обратитесь к администратору.';
                }
                beautiful_alert(error_text);
            });
    }

    render() {
        return (
            <React.Fragment>
                <div id={this.props.div_id} className="bookmark-content" style={{display:'none'}}>

                    {!this.props.is_admin && <div id="write-review">
                        <p>{this.props.header}</p>

                        <div className="edit-comment">
                            <textarea id="add-review-title" name="title" value={this.state.title} onChange={this.handleInputChange} placeholder="заголовок"/>

                            <form id="add-review-form" action="POST">
                                <TextInputWithButtons key={this.state.text_input_key} name="html" text={this.state.html} onTextChange={this.handleInputChange} placeholder={this.props.placeholder}/>

                                <p>Добавьте ссылки на работы, которые упоминаются в тексте (по одной ссылке в строке, только на работы на сайте Nebooker).</p>
                                <textarea id="add-links-area" name="links" value={this.state.links} onChange={this.handleInputChange} />

                                <span id="add-review-go" className="bttn-dark bttn" onClick={this.handleSubmit}>отправить</span>
                            </form>
                        </div>
                    </div>}

                    <p>{this.props.table_name}</p>
                    <div className="overflow-div">
                        <table className="profile-table">
                            <tbody>
                                {this.state.entries.map((entry) => <tr key={String(entry.id)}>
                                    {this.props.is_admin && <td className="name1">{entry.user_name}</td>}
                                    {!this.props.is_admin && <td className="title1">
                                        {entry.works.map((w) => <a className='reviews-table-work-link' key={String(entry.id) + '_' + String(w.id)} href={'/works/' + String(w.id)}>{w.title}</a>)}
                                        </td>}
                                    <td className="header1"><a href={entry.type == 'column_review' ? ('/columns/' + String(entry.id)) : ('/reviews/' + String(entry.id))}>{entry.title || 'без названия'}</a></td>
                                    <td className="comment1">{entry.previewable_text}</td>
                                    {!this.props.is_admin && <td className="reject">{entry.status_image_src ? <img src={entry.status_image_src} title={entry.status_image_hint}/> : entry.status}</td>}
                                    {this.props.is_admin && <React.Fragment>
                                        <td className="premod-edit"><a href={"/reviews/" + String(entry.id) + "/edit"}><img src="/images/icons/pencil.png" title={"редактировать"} /></a></td>
                                        <td className="premod-approve">{entry.approvable && <a href="">
                                            <ActionWithConfirmation request_method='POST'
                                            request_path={this.props.accept_base_path + entry.id + "/accept"} text='одобрить'
                                            image_title='опубликовать' confirmation_text='Если у отзыва не было выставлено время, он будет опубликован прямо сейчас. Продолжить?'
                                            cancel_text='нет' yes_text='да' image_src='/images/icons/check.png'
                                            callback={this.reloadEntriesWithCurrentPage} />
                                        </a>}</td>
                                        <td className="premod-reject">{entry.approvable && <a href="">
                                            <IconClickControl title="отклонить" filled={false} counter={false}
                                                              request_path={this.props.decline_base_path + entry.id + "/decline"}
                                                              filled_image_src='/images/cross_white_filled.png'
                                                              image_src='/images/icons/cross.png'
                                                              needs_fingerprint={false} callback={this.reloadEntries}
                                                              is_button={false} button_text='' button_undo_text=''/>
                                        </a>}</td>
                                    </React.Fragment>}
                                </tr>)}
                            </tbody>
                        </table>
                    </div>
                    { this.props.total_pages !== null && this.state.total_pages > 1 && this.state.total_count > 0 &&
                    <div className="user_works_content user-works-page-counter">
                        <a id="first-p" className={this.state.is_first ? 'pagination-link-inactive' : ''} href={''} onClick={this.toFirstPage}>&#171;</a>
                        <a id="previos-p" className={this.state.is_first ? 'pagination-link-inactive' : ''} href={''} onClick={this.toPrevPage}>&#8249;</a>
                        <span id='current-p'>{this.state.current_page}</span>
                        <a id="next-p" className={this.state.is_last ? 'pagination-link-inactive' : ''} href={''} onClick={this.toNextPage}>&#8250;</a>
                        <a id="last-p" className={this.state.is_last ? 'pagination-link-inactive' : ''} href={''} onClick={this.toLastPage}>&#187;</a>
                        <span>
                        <select className="goto-page" id="goto-page" name='goto_page' onChange={this.gotoPage} value={this.state.current_page}>
                            {Array.from({length: this.state.total_pages}, (_, i) => i + 1).map((item) =>
                                <option value={item} key={'gotopage_' + String(item)}>{item}</option>
                            )}
                        </select>
                        </span>
                    </div>
                    }
                </div>
            </React.Fragment>
        )
    }
}

ReviewsTable.propTypes = {
    header: PropTypes.string,
    table_name: PropTypes.string,
    placeholder: PropTypes.string,
    entries: PropTypes.array,
    total_pages: PropTypes.number,
    total_count: PropTypes.number,
    user_id: PropTypes.number,
    is_admin: PropTypes.bool,
    div_id: PropTypes.string,
    accept_base_path: PropTypes.string,
    decline_base_path: PropTypes.string

}

export default ReviewsTable