window.onload = function() {
    /*разворачивает менюхи*/
    var selectedP;
    let menu = document.getElementById("mennu");
    if (menu) {
        menu.onclick = function (event) {
            var target = event.target;
            if (target.tagName != "P") return;
            openList(target);
        };
    }

    function openList(p) {
        if (selectedP) {
            let should_break = false;
            if (selectedP.nextElementSibling.style.visibility === "visible") {
                should_break = true;
            }
            selectedP.style.color = "#bababa";
            selectedP.nextElementSibling.style.visibility = "hidden";
            selectedP.nextElementSibling.classList.remove("dopler");
            if (p === selectedP && should_break) {
                selectedP = null;
                return;
            }
        }
        selectedP = p;
        selectedP.style.color = "#ffffff";
        selectedP.nextElementSibling.style.visibility = "visible";
        selectedP.nextElementSibling.classList.add("dopler");
    }

    /*открытие-закрытие формы*/
    var selectedA;
    const forms = document.getElementById("forms");
    if (forms) {
        forms.onclick = function (event) {
            var target = event.target;
            if (target.tagName != "A") return;
            openForm(target);
        }
    }

    function openForm(a) {
        if (selectedA) {
            selectedA.style.color = "#bababa";
            selectedA.nextElementSibling.style.display = "none";
        }
        selectedA = a;
        selectedA.style.color = "#ffffff";
        selectedA.nextElementSibling.style.display = "block";
    }

    /*закрывашка для меню*/
    var closeId;
    for (var i = 1; i < 7; i++) {
        closeId = "close-list" + i;
        var element = document.getElementById(closeId);
        if (element) {
            document.getElementById(closeId).onclick = function () {
                var x = this;
                x.parentNode.style.visibility = "hidden";
            }
        }
    }

    /*закрывашки для ворнинга*/
    // document.getElementById("in").addEventListener("click", closeModal);
    // document.getElementById("ok").addEventListener("click", closeModal);
    //
    // function closeModal() {
    //     event.preventDefault();
    //     document.getElementById("modal").classList.remove("show-modal");
    // }

    /*закрывашки для нотайсов в меню*/
    const closeSign1 = document.getElementById("close-sign1");
    if (closeSign1) {
        closeSign1.addEventListener("click", closeSign);
    }
    const closeSign2 = document.getElementById("close-sign2");
    if (closeSign2) {
        closeSign2.addEventListener("click", closeSign);
    }

    function closeSign() {
        this.parentNode.style.display = "none";
    }


    /*мотает вниз*/
    if (document.getElementById("tocomment")) {
        document.getElementById("tocomment").addEventListener("click", goDown);
    }

    function goDown() {
        location.href = '#add-comment-form';
    }

    /*открывашка-закрывашка для панельки одобрить-ниадобрить*/
    if (document.getElementById("close-open-control")) {
        document.getElementById("close-open-control").addEventListener("click", openLitlePanel)
    }

    var closeOpenControl = 0;

    function openLitlePanel() {
        if (closeOpenControl == 0) {
            document.getElementById("control-top-div").style.padding = "0 10px 5px 10px";
            document.getElementById("control-top-div").style.width = "20px";
            closeOpenControl = 1;
        } else {
            document.getElementById("control-top-div").style.padding = "0 0 5px 0";
            document.getElementById("control-top-div").style.width = "0";
            return closeOpenControl = 0;
        }
    }

    const elems = document.getElementsByClassName("title-img-popuppable");
    // console.log('HERE1', elems);
    for (let i = 0; i < elems.length; i++) {
        // console.log('HERE2');
        elems[i].addEventListener("mouseover", showImgPopUp);
        elems[i].addEventListener("mouseout", closeImgPopUp);
    }

    function showImgPopUp(event) {
        // console.log('HERE6');
        let popup = document.getElementById("title-image-pop-up");
        popup.style.width = "110px";
        popup.style.padding = "10px";
        popup.style.fontSize = "11px";
        popup.style.top = String(event.clientY + 5) + 'px';
        popup.style.left = String(event.clientX + 5) + 'px';
        popup.innerHTML = event.target.dataset.title;
    }

    function closeImgPopUp() {
        // console.log('HERE7');
        let popup = document.getElementById("title-image-pop-up");
        popup.style.width = "0";
        popup.style.padding = "0";
        popup.style.fontSize = "0";
    }


    /*открывашка для выбора формата файла*/

    if (document.getElementById("download")) {
        document.getElementById("download").addEventListener("click", openDownloadPopUp);
    }

    function openDownloadPopUp(e) {
        e.preventDefault();
        document.getElementById("download-pop-up").style.width = "210px";
        document.getElementById("download-pop-up").style.padding = "10px 16px";
        document.getElementById("download-pop-up").style.fontSize = "14px";

        document.getElementById("download-pop-up").addEventListener("click", closePopUp);

        function closePopUp() {
            document.getElementById("download-pop-up").style.width = "0";
            document.getElementById("download-pop-up").style.padding = "0";
            document.getElementById("download-pop-up").style.fontSize = "0";
        }
    }

    if (document.getElementById("download2")) {
        document.getElementById("download2").addEventListener("click", openDownloadPopUp2);
    }

    function openDownloadPopUp2(e) {
        e.preventDefault();
        if (document.getElementById("download-pop-up2").style.width === "210px") {
            return;
        }
        document.getElementById("download-pop-up2").style.width = "210px";
        document.getElementById("download-pop-up2").style.padding = "10px 16px";
        document.getElementById("download-pop-up2").style.fontSize = "14px";

        document.getElementById("download-pop-up2").addEventListener("click", closePopUp2);
        document.addEventListener("click", closePopUp2IfNeeded);


        function closePopUp2() {
            document.getElementById("download-pop-up2").style.width = "0";
            document.getElementById("download-pop-up2").style.padding = "0";
            document.getElementById("download-pop-up2").style.fontSize = "0";
        }

        let prevent_close = true;

        function closePopUp2IfNeeded() {
            let elem = document.getElementById("download-pop-up2");
            if (elem && elem.style.width !== "0" && !prevent_close) {
                closePopUp2();
                prevent_close = true;
                document.removeEventListener("click", closePopUp2IfNeeded);
            }
            if (prevent_close) {
                prevent_close = false;
            }
        }
    }

    /*закрашивает иконки по клику*/

    if (document.getElementById("short")) {
        document.getElementById("short").addEventListener("click", short);
    }

    function short() {
        this.setAttribute("src", "http://static.diary.ru/userdir/1/8/0/9/180955/86654809.png")
    }

    if (document.getElementById("fap")) {
        document.getElementById("fap").addEventListener("click", fap);
    }

    function fap() {
        this.setAttribute("src", "http://static.diary.ru/userdir/1/8/0/9/180955/86654810.png")
    }

    if (document.getElementById("refuse")) {
        document.getElementById("refuse").addEventListener("click", refuse);
    }

    function refuse() {
        this.setAttribute("src", "http://static.diary.ru/userdir/1/8/0/9/180955/86654811.png")
    }

    if (document.getElementById("accept")) {
        document.getElementById("accept").addEventListener("click", accept);
    }

    function accept() {
        this.setAttribute("src", "http://static.diary.ru/userdir/1/8/0/9/180955/86654809.png")
    }

    if (document.getElementById("vote")) {
        document.getElementById("vote").addEventListener("click", vote);
    }

    function vote() {
        this.setAttribute("src", "http://static.diary.ru/userdir/1/8/0/9/180955/86654808.png")
    }

    if (document.getElementById("like")) {
        document.getElementById("like").addEventListener("click", like);
    }

    function like() {
        this.setAttribute("src", "https://secure.diary.ru/userdir/1/8/0/9/180955/86653202.png")
    }


    const more_labels = document.getElementsByClassName("more_label");
    const openMore = function() {
        this.style.display = 'none';
        this.nextSibling.style.display = 'block';
    };
    for (let i = 0; i < more_labels.length; i++) {
        more_labels[i].addEventListener('click', openMore, false);
    }

    const mores = document.getElementsByClassName("more_container");
    const closeMore = function() {
        this.style.display = 'none';
        this.previousSibling.style.display = 'block';
    };
    for (let i = 0; i < mores.length; i++) {
        mores[i].addEventListener('dblclick', closeMore, false);
    }

    /*разворачивает номинации в сортировке и всякие инпуты в добавлении фэндомоа-персонажей*/
    var selectedSPAN;
    let f = document.getElementById("lc-fandom");
    if (f) {
        f.addEventListener("click", openHidden);
    }
    let s = document.getElementById("lc-sorting");
    if (s) {
        s.addEventListener("click", openHidden);
    }
    let c = document.getElementById("lc-character");
    if (c) {
        c.addEventListener("click", openHidden);
    }

    function openHidden(event) {
        var target = event.target;
        if (target.tagName != "SPAN") return;
        if (target.id === "current-p") return;
        openSortinDiv(target);
    }
    function openSortinDiv(span) {
        selectedSPAN = span;
        const elem = selectedSPAN.nextElementSibling;
        if (elem) {
            if (elem.style.display === 'block') {
                elem.style.display = 'none';
            } else {
                elem.style.display = 'block';
            }
        }
    }

    /*открывает вкладки*/
    var selectedDIV;
    if (document.getElementById("book-marks")) {
        document.getElementById("book-marks").onclick = function (event) {
            var target = event.target;
            if (target.tagName != "DIV") return;
            const elems = document.getElementsByClassName("book-mark-active");
            if (elems.length > 0) {
                const active_tab = elems[0];
                if (active_tab) {
                    active_tab.classList.remove("book-mark-active");
                }
            }
            activeBookmark(target);
        }
    }
    function activeBookmark(div) {
        if (selectedDIV) {
            selectedDIV.style.color = "#777777";
            selectedDIV.style.background = "#eeeeee";
            selectedDIV.style.borderBottom = "#dfdfd6";
        }
        selectedDIV = div;
        selectedDIV.style.color = "#333333";
        selectedDIV.style.background = "#f3f3f3";
        selectedDIV.style.borderBottom = "1px solid #f3f3f3";
    }

    function hideAllTabsExcept(tab_id) {
        const all_tabs = [
            'lc-profile',
            'lc-favorites',
            'lc-sorting',
            'lc-sorting-results',
            'lc-shortlist-results',
            'lc-works',
            'lc-premod',
            'lc-character',
            'lc-fandom',
            'lc-comments',
            'lc-shortlist-0',
            'lc-shortlist-1',
            'lc-shortlist-2',
            'lc-shortlist-3',
            'lc-shortlist-4',
            'lc-shortlist-5',
            'lc-shortlist-6',
            'lc-shortlist-7',
            'lc-shortlist-8',
            'lc-shortlist-9',
            'lc-review',
            'lc-review-premod',
            'lc-mail',
            'lc-send-mail',
            'lc-private-forum',
            'lc-public-forum'
        ];
        if (all_tabs.includes(tab_id)) {
            for (let i = 0; i < all_tabs.length; i++) {
                let elem = document.getElementById(all_tabs[i]);
                if (elem) {
                    if (all_tabs[i] === tab_id) {
                        elem.style.display = "block";
                    } else {
                        elem.style.display = "none";
                    }
                }
            }
            window.location.hash = tab_id.substr(3);
        }
    }

    if (document.getElementById("profile_link")) {
        document.getElementById("profile_link").addEventListener("click", openProfile);
    }
    function openProfile() {
        hideAllTabsExcept('lc-profile');
    }
    if (document.getElementById("works_link")) {
        document.getElementById("works_link").addEventListener("click", openWorks);
    }
    function openWorks() {
        hideAllTabsExcept('lc-works');
    }
    if (document.getElementById("favorites_link")) {
        document.getElementById("favorites_link").addEventListener("click", openFavorites);
    }
    function openFavorites() {
        hideAllTabsExcept('lc-favorites');
    }
    if (document.getElementById("sorting_link")) {
        document.getElementById("sorting_link").addEventListener("click", openSorting);
    }
    function openSorting() {
        hideAllTabsExcept('lc-sorting');
    }

    if (document.getElementById("sorting_results_link")) {
        document.getElementById("sorting_results_link").addEventListener("click", openSortingResults);
    }
    function openSortingResults() {
        hideAllTabsExcept('lc-sorting-results');
    }


    if (document.getElementById("shortlist_results_link")) {
        document.getElementById("shortlist_results_link").addEventListener("click", openShortlistResults);
    }
    function openShortlistResults() {
        hideAllTabsExcept('lc-shortlist-results');
    }


    if (document.getElementById("premoderation_link")) {
        document.getElementById("premoderation_link").addEventListener("click", openPremod);
    }
    function openPremod() {
        hideAllTabsExcept('lc-premod');
    }
    if (document.getElementById("add_character_link")) {
        document.getElementById("add_character_link").addEventListener("click", openCharacter);
    }
    function openCharacter() {
        hideAllTabsExcept('lc-character');
    }
    if (document.getElementById("add_fandom_link")) {
        document.getElementById("add_fandom_link").addEventListener("click", openFandom);
    }
    function openFandom() {
        hideAllTabsExcept('lc-fandom');
    }
    if (document.getElementById("comments_link")) {
        document.getElementById("comments_link").addEventListener("click", openComms);
    }
    function openComms() {
        hideAllTabsExcept('lc-comments');
    }

    for (let i = 0; i < 10; i ++) {
        if (document.getElementById("shortlist_link_" + String(i))) {
            document.getElementById("shortlist_link_" + String(i)).addEventListener("click", openShort);
        }
    }
    function openShort(event) {
        hideAllTabsExcept('lc-shortlist-' + String(event.target.dataset.index));
    }
    if (document.getElementById("reviews_link")) {
        document.getElementById("reviews_link").addEventListener("click", openReviews);
    }
    function openReviews() {
        hideAllTabsExcept('lc-review');
    }

    if (document.getElementById("reviews_moderation_link")) {
        document.getElementById("reviews_moderation_link").addEventListener("click", openReviewsModeration);
    }
    function openReviewsModeration() {
        hideAllTabsExcept('lc-review-premod');
    }

    if (document.getElementById("mail_link")) {
        document.getElementById("mail_link").addEventListener("click", openMail);
    }
    function openMail() {
        hideAllTabsExcept('lc-mail');
    }

    if (document.getElementById("send_mail_link")) {
        document.getElementById("send_mail_link").addEventListener("click", openSendMail);
    }
    function openSendMail() {
        hideAllTabsExcept('lc-send-mail');
    }

    if (document.getElementById("public_forum_link")) {
        document.getElementById("public_forum_link").addEventListener("click", openPublicForum);
    }
    function openPublicForum() {
        hideAllTabsExcept('lc-public-forum');
    }

    if (document.getElementById("private_forum_link")) {
        document.getElementById("private_forum_link").addEventListener("click", openPrivateForum);
    }
    function openPrivateForum() {
        hideAllTabsExcept('lc-private-forum');
    }

    const ids = {
        'profile': 'profile_link',
        'favorites': 'favorites_link',
        'sorting': 'sorting_link',
        'sorting-results': 'sorting_results_link',
        'shortlist-results': 'shortlist_results_link',
        'works': 'works_link',
        'premod': 'premoderation_link',
        'character': 'add_character_link',
        'fandom': 'add_fandom_link',
        'comments': 'comments_link',
        'shortlist-0': 'shortlist_link_0',
        'shortlist-1': 'shortlist_link_1',
        'shortlist-2': 'shortlist_link_2',
        'shortlist-3': 'shortlist_link_3',
        'shortlist-4': 'shortlist_link_4',
        'shortlist-5': 'shortlist_link_5',
        'shortlist-6': 'shortlist_link_6',
        'shortlist-7': 'shortlist_link_7',
        'shortlist-8': 'shortlist_link_8',
        'shortlist-9': 'shortlist_link_9',
        'review': 'reviews_link',
        'review-premod': 'reviews_moderation_link',
        'mail': 'mail_link',
        'send-mail': 'send_mail_link',
        'public-forum' : 'public_forum_link',
        'private-forum' : 'private_forum_link'
    };
    let reversed_ids = {};
    Object.keys(ids).forEach((key) => {
        reversed_ids[ids[key]] = key;
    });

    if (document.getElementById("book-marks")) {
        const key = window.location.hash.substr(1);
        if (key) {
            const div = document.getElementById(ids[key]);
            if (div) {
                hideAllTabsExcept('lc-' + key);
                const elems = document.getElementsByClassName("book-mark-active");
                if (elems.length > 0) {
                    const active_tab = elems[0];
                    if (active_tab) {
                        active_tab.classList.remove("book-mark-active");
                    }
                }
                activeBookmark(div);
            }
        } else {
            const tabs = document.getElementById('book-marks');
            if (tabs) {
                const child = tabs.firstChild;
                if (child) {
                    const elems = document.getElementsByClassName("book-mark-active");
                    if (elems.length > 0) {
                        const active_tab = elems[0];
                        if (active_tab) {
                            active_tab.classList.remove("book-mark-active");
                        }
                    }
                    activeBookmark(child);
                    hideAllTabsExcept('lc-' + reversed_ids[child.id]);
                }
            }
        }
    }

    function hideAllClassElems(name) {
        let elems = document.getElementsByClassName(name);
        for (let i = 0; i < elems.length; i++) {
            elems[i].style.display = "none";
        }
    }
    function showAllClassElems(name) {
        let elems = document.getElementsByClassName(name);
        for (let i = 0; i < elems.length; i++) {
            elems[i].style.display = "block";
        }
    }

    if (document.getElementById('search-submit')) {
        document.getElementById('search-submit').addEventListener("click", startSearch)
    }

    function startSearch(event) {
        event.preventDefault();
        if (document.getElementById('search-input')) {
            const keywords = document.getElementById('search-input').value;
            window.location.href = ("/search?keywords=" + keywords)
        }
    }

    /*разворачивает инпуты для редактирования профиля*/
    if (document.getElementById("profile-edit-button")) {
        document.getElementById("profile-edit-button").addEventListener("click", openArea);
    }
    var openCloseArea = 0;
    function openArea() {
        if (openCloseArea == 0) {
            document.getElementById("edit1").style.height = "60px";
            document.getElementById("edit2").style.height = "100px";
            document.getElementById("edit3").style.height = (String(60 * 4) + "px");
            document.getElementById("edit4").style.height = "290px";
            document.getElementById("edit5").style.height = "60px";
            openCloseArea = 1;
        } else {
            document.getElementById("edit1").style.height = "0";
            document.getElementById("edit2").style.height = "0";
            document.getElementById("edit3").style.height = "0";
            document.getElementById("edit4").style.height = "0";
            document.getElementById("edit5").style.height = "0";
            return openCloseArea = 0;
        }
    }

    const open_reg_button = document.getElementById("share");
    if (open_reg_button && open_reg_button.classList.contains('open-menu-needed')) {
        open_reg_button.addEventListener("click", openReg);
    }

    const open_reg_link = document.getElementById("contest-menu-add-work");
    if (open_reg_link && open_reg_link.classList.contains('open-menu-needed')) {
        open_reg_link.addEventListener("click", openReg);
    }

    const open_reg_vote_bttn = document.getElementById("vote-bttn");
    if (open_reg_vote_bttn && open_reg_vote_bttn.classList.contains('open-menu-needed')) {
        open_reg_vote_bttn.addEventListener("click", openReg);
    }

    function openReg(event) {
        event.preventDefault();
        const target = document.getElementById('profile').previousElementSibling;
        openList(target);
        const formTarget = document.getElementById('open-form2');
        openForm(formTarget);
        if (event.target.dataset.reg_text) {
            document.getElementById('menu-notice').textContent = event.target.dataset.reg_text;
        } else {
            document.getElementById('menu-notice').textContent = 'Зарегистрируйтесь, чтобы подать работу.';
        }
        document.getElementById('sign2').style.display = 'block';

    }

    // Array.from(document.getElementsByClassName("open-note")).forEach((item) => {
    //     item.addEventListener("click", openJuryNote);
    // });
    // function openJuryNote(event) {
    //     event.preventDefault();
    //     const target = event.target.nextElementSibling;
    //     target.style.display = 'block';
    // }
    //
    // Array.from(document.getElementsByClassName("close-note")).forEach((item) => {
    //     item.addEventListener("click", closeJuryNote);
    // });
    // function closeJuryNote(event) {
    //     event.preventDefault();
    //     const target = event.target.parentElement;
    //     target.style.display = 'none';
    // }

    const page_turner = document.getElementById("goto-page");
    if (page_turner && page_turner.classList.contains('goto-page-needed')) {
        page_turner.addEventListener("change", go_to_page);
    }

    function go_to_page(event) {
        window.location.href = event.target.dataset.base_path + String(event.target.value) + event.target.dataset.tail_path
    }
};

export const beautiful_alert = function(text) {
    document.getElementById("alert-modal").classList.add("show-modal");
    document.getElementById("warning").innerHTML = text;
    document.getElementById("out").style.display = "none";

    let in_button = document.getElementById("in");
    in_button.innerHTML = 'ок';
    in_button.addEventListener('click', close_beautiful_alert, {once: true});
};

export const beautiful_alert_confirmation = function(text, yes_text, cancel_text, yes_callback) {
    document.getElementById("alert-modal").classList.add("show-modal");
    document.getElementById("warning").innerHTML = text;

    let in_button = document.getElementById("in");
    in_button.innerHTML = yes_text;
    in_button.addEventListener('click', yes_callback, {once: true});

    let cancel_button = document.getElementById("out");
    cancel_button.style.display = "inline-block";
    cancel_button.innerHTML = cancel_text;
    cancel_button.addEventListener('click', close_beautiful_alert);
};

export const close_beautiful_alert = function(event) {
    if (event) {
        event.preventDefault();
    }
    document.getElementById("alert-modal").classList.remove("show-modal");
};

export const go_to_page = function(event) {
  window.location.href = event.target.dataset.base_path + String(event.target.value)
};