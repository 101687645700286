import React from "react"
import PropTypes from "prop-types";

const Functions = require('./functions.js');
const axios = require('axios').default;

class AdminNominationsDataTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            entries: this.props.entries
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();
        let method = 'POST';
        let path = this.props.request_path;
        const self = this;
        let data = {
            authenticity_token: Functions.getMetaContent("csrf-token"),
            entries: this.state.entries
        };

        axios({method: method, url: path, data: data, responseType: 'json'})
            .then(function (response) {
                alert('Успешно сохранено');
            })
            .catch(function (error) {
                let error_text = '';
                if (error.response && error.response.data && (error.response.data.error || error.response.data.errors)) {
                    if (error.response.data.error) {
                        error_text = error.response.data.error;
                    } else if (error.response.data.errors) {
                        error_text = error.response.data.errors;
                    } else {
                        error_text = 'Неизвестная ошибка. Обратитесь к администратору.';
                    }
                } else {
                    error_text = 'Неизвестная ошибка. Обратитесь к администратору.';
                }
                alert(error_text);
            });
    }

    handleInputChange(e) {
        const target = e.target;
        const name = target.name;

        let new_data = this.state.entries;
        if (this.props.winners) {
            let value;
            if (this.props.main_nomination) {
                value = target.value;
                new_data[Number(name)]['place'] = value;
            } else {
                value = target.checked;
                new_data[Number(name)]['winner'] = value;
            }
        } else {
            const value = target.checked;
            new_data[Number(name)]['in_shortlist'] = value;
        }

        this.setState({
            ['entries']: new_data
        });
    }

    render() {
        return (
            <React.Fragment>
                <table>
                    <thead><tr>
                    <th>id</th><th>title</th><th>work score</th>
                        {!this.props.is_guest_voting && this.props.voters.map((voter) => <th key={'voter_header_' + String(voter.id)}>{voter.user_name + (voter.winners_order_fixed ? ' (решил)' : '')}</th>)}
                        {this.props.admin_can_edit_result && (this.props.winners ? <th>winner</th> : <th>in shortlist</th>)}
                    </tr></thead>
                    <tbody>
                    {this.props.entries.map((entry, index) =>
                        <tr key={String(entry.id)} className={index % 2 === 0 ? 'even' : 'odd'}>
                            <td><a href={'/admin/works/' + String(entry.id)}>{entry.id}</a></td>
                            <td>{entry.title}</td>
                            <td>{entry.work_score}</td>
                            {!this.props.is_guest_voting && this.props.voters.map((voter) => <td key={'voter_score_' + String(voter.id)}>{entry['voter_score_' + String(voter.id)]}</td>)}
                            {this.props.admin_can_edit_result && !this.props.winners && <td>
                                <input type="checkbox" name={String(index)} defaultChecked={entry.in_shortlist} onChange={this.handleInputChange}/>
                            </td>}
                            {this.props.admin_can_edit_result && this.props.winners && this.props.is_guest_voting && <td>
                                <input type="checkbox" name={String(index)} defaultChecked={entry.guest_rank === 1} onChange={this.handleInputChange}/>
                            </td>}
                            {this.props.admin_can_edit_result && this.props.winners && !this.props.main_nomination && !this.props.is_guest_voting && <td>
                                <input type="checkbox" name={String(index)} defaultChecked={entry.winner_fap} onChange={this.handleInputChange}/>
                            </td>}
                            {this.props.admin_can_edit_result && this.props.winners && this.props.main_nomination && <td>
                                <input type="text" name={String(index)} value={this.state.entries[index]['place'] || ''} onChange={this.handleInputChange}/>
                            </td>}
                        </tr>)}
                    </tbody>
                </table>
                {this.props.admin_can_edit_result && <input type="submit" value="сохранить" onClick={this.handleSubmit}/>}
            </React.Fragment>
        )
    }
}

AdminNominationsDataTable.propTypes = {
    entries: PropTypes.array,
    voters: PropTypes.array,
    request_path: PropTypes.string,
    admin_can_edit_result: PropTypes.bool,
    is_guest_voting: PropTypes.bool,
    winners: PropTypes.bool,
    main_nomination: PropTypes.bool

};

export default AdminNominationsDataTable;