import React from "react"
import PropTypes from "prop-types";
import SelectInput from "./SelectInput";
import CombinedSelectInput from "./CombinedSelectInput";

class SearchFilter extends React.Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClear = this.handleClear.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.state = this.props.filter_params;
    }

    handleSubmit(event) {
        if (event) {
            event.preventDefault();
        }
        let params_string = Object.keys(this.state).map((item) => {
            if (this.state[item] && this.state[item] !== -1 && this.state[item] !== '-1') {
                return item + '=' + this.state[item];
            } else {
                return null;
            }
        }).filter((value) => value !== undefined && value !== null && value !== '' && value !== 0 && value !== -1 && value !== '-1').join('&');
        if (params_string) {
            params_string = '?' + params_string;
        }
        window.location.href = this.props.base_url + params_string;
    }

    handleClear(event) {
        event.preventDefault();
        let new_state = {};
        Object.keys(this.state).forEach((key) => new_state[key] = '');
        this.setState(new_state);
    }

    handleInputChange(event) {
        const target = event.target;
        let value, name;
        if (target) {
            value = target.value;
            name = target.name;
        } else {
            value = event.detail.value;
            name = event.detail.name;
        }
        console.log('HERE22', name, value);
        this.setState({
            [name]: value
        });
    }

    render() {
        return (
            <React.Fragment>

                <div className="lines">

                    <SelectInput class_name="litle-column" selected={ Number(this.state.year) } list={this.props.lists_data.years}
                                 name='year' include_blank={true} blank_name={'год'} onSelectChange={this.handleInputChange} />

                    <SelectInput selected={ Number(this.state.nomination) } list_array={this.props.lists_data.nominations}
                                 name='nomination' include_blank={true} blank_name={'номинация'} onSelectChange={this.handleInputChange} />

                    <SelectInput selected={ Number(this.state.award) } list={this.props.lists_data.awards}
                                 name='award' include_blank={true} blank_name={'награды'} onSelectChange={this.handleInputChange} />


                </div>

                <div className="lines">

                    <SelectInput selected={ Number(this.state.category) } list={this.props.lists_data.categories}
                                 name='category' include_blank={true} blank_name={'категория'} onSelectChange={this.handleInputChange} />

                    <SelectInput class_name="litle-column" selected={ Number(this.state.rating) } list={this.props.lists_data.ratings}
                                 name='rating' include_blank={true} blank_name={'рейтинг'} onSelectChange={this.handleInputChange} />

                    <SelectInput selected={ Number(this.state.genre) } list={this.props.lists_data.genres}
                                 name='genre' include_blank={true} blank_name={'жанр'} onSelectChange={this.handleInputChange} />

                </div>

                <div className="lines">
                    <SelectInput class_name="litle-column" selected={ Number(this.state.size) } list={this.props.lists_data.sizes}
                                 name='size' include_blank={true} blank_name={'размер'} onSelectChange={this.handleInputChange} />

                    <input id="search-words" className="search-inputs" type="search" name="key_words"
                           placeholder="по ключевым словам" autoComplete="off" value={this.state.key_words}
                           onChange={this.handleInputChange}/>

                    <input id="search-characters" className="search-inputs" type="search" name="character"
                           placeholder="по персонажам" autoComplete="off" value={this.state.character}
                           onChange={this.handleInputChange}/>


                </div>

                <div className="lines">

                    <CombinedSelectInput class_name="combined-litle-column" selected={ Number(this.state.author) } list_array={this.props.lists_data.authors}
                                                                      dictionary={this.props.lists_data.authors_dictionary}
                                                                      name='author' include_blank={true} blank_name={'по авторам'} onSelectChange={this.handleInputChange}
                                                                      with_suggestions={true} customStyles={this.props.customStyles} customFocusedStyles={this.props.customFocusedStyles}/>

                    <CombinedSelectInput class_name="combined-litle-column" selected={ Number(this.state.fandom) } list_array={this.props.lists_data.fandoms}
                                                                      dictionary={this.props.lists_data.fandoms_dictionary}
                                                                      name='fandom' include_blank={true} blank_name={'по фэндому'} onSelectChange={this.handleInputChange}
                                                                      with_suggestions={true} customStyles={this.props.customStyles} customFocusedStyles={this.props.customFocusedStyles}/>

                    <SelectInput selected={ 0 } list={{}} className={'hidden-archive-select'}
                                 name='hidden_select' include_blank={true} blank_name={''} onSelectChange={this.handleInputChange} />



                </div>


                <div className="lines bttn-line">
                    <input id="start-search" className="bttn-light" type="submit" value="искать" onClick={this.handleSubmit}/>
                    <input id="clear-search" className="bttn-light" type="submit" value="сбросить" onClick={this.handleClear}/>
                </div>

            </React.Fragment>
        );
    }


}

SearchFilter.propTypes = {
    filter_params: PropTypes.object,
    lists_data: PropTypes.object,
    customStyles: PropTypes.array,
    customFocusedStyles: PropTypes.object,
    base_url: PropTypes.string,
    admin_testing: PropTypes.bool
};

export default SearchFilter